import ApiService from './ApiService';
import { API_AUTH, MEMBERS, ORGANIZATIONS, LEAVE } from '../core/CcxEnv';
import Members from '../types/Members';

// constants
const API_URL = API_AUTH;

export default class MembersService extends ApiService {
    /**
     * Get list of all firewall rules.
     * @param {string} uuid
     */
    static async getAllMembers(orgId: string): Promise<Members> {
        const response = await this.request(
            'GET',
            `${ORGANIZATIONS}/${orgId}/${MEMBERS}`
        );
        return new Members(response);
    }

    static async deleteMember(orgId: string, memberId: string): Promise<any[]> {
        const response = await this.request(
            'DELETE',
            `${ORGANIZATIONS}/${orgId}/${MEMBERS}/${memberId}`
        );
        return response;
    }

    static async leaveOrganization(orgId: string): Promise<any[]> {
        const response = await this.request(
            'DELETE',
            `${ORGANIZATIONS}/${orgId}/${LEAVE}`
        );
        return response;
    }

    static async updateMember(
        orgId: string,
        userId: string,
        role: string
    ): Promise<any[]> {
        const requestData = {
            data: {
                role,
            },
        };
        const response = await this.request(
            'PATCH',
            `${ORGANIZATIONS}/${orgId}/${MEMBERS}/${userId}`,
            requestData
        );
        return response;
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
