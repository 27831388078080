import { Button, Col, Row, Tooltip } from 'antd';
import AppTable from '../AppTable';
import DbParametersActionsLists from './DbParametersActionsLists';
import styles from './DbParametersTable.module.less';
import CreateDbParameters from './CreateDbParameters';
import useDbParametersGroup from '../../core/hooks/useDbParametersGroup';
import AppLoading from '../AppLoading';
import { useEffect, useMemo, useState } from 'react';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import { DbParametersGroup } from '../../types/DbParametersGroup';
import { DbParameterGroup } from '../../types/DbParameterGroup';
import { RootState } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import {
    CAN_ADD_DB_PARAMS,
    CAN_MODIFY_DB_PARAMS,
} from '../../core/PermissionRoles';
import Can from '../../core/providers/Can';

interface TableColumn {
    title: string;
    dataIndex?: string;
    key: string;
    align?: 'left' | 'right' | 'center';
    render?: (text: string, record: DbParameterGroup) => React.ReactNode;
}

interface PaginationProps {
    page: number;
    perPage: number;
    total: number;
}

const DbParametersTable = () => {
    const {
        dbParameters: dbParametersGroup,
        loading: loadingGroup,
        error: errorGroup,
        refresh,
    } = useDbParametersGroup();

    const [pagination, setPagination] = useState<PaginationProps>();
    const [tableData, setTableData] = useState<DbParameterGroup[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { deploymentOptions } = useAppSelector(
        (state: RootState) => state.deploymentOptions
    );

    useEffect(() => {
        if (dbParametersGroup) {
            setPagination({
                page: dbParametersGroup.pagination.currentPage,
                perPage: dbParametersGroup.pagination.pageSize,
                total: dbParametersGroup.pagination.totalRecords,
            });

            if (deploymentOptions) {
                setTableData(dbParametersGroup.parameterGroups);
            }
        }
    }, [dbParametersGroup, deploymentOptions]);

    useEffect(() => {
        setIsLoading(loadingGroup);
    }, [loadingGroup]);

    const onChangePage = async (nextPage: any) => {
        setIsLoading(true);
        setPagination({
            ...pagination,
            page: nextPage.current,
            perPage: nextPage.pageSize,
            total: nextPage.total,
        });

        try {
            const update: DbParametersGroup =
                await DbParameterGroupService.getDbParameterGroups(
                    nextPage?.perPage,
                    nextPage.current
                );

            setTableData(update.parameterGroups);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const tableColumns: TableColumn[] = useMemo(
        () => [
            {
                title: 'Group Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Vendor and Version',
                key: 'vendorVersion',
                render: (_, record) => (
                    <div>
                        <span>{record.databaseVendor}</span>{' '}
                        <span>{record.databaseVersion}</span>
                    </div>
                ),
            },
            {
                title: 'Datastores',
                dataIndex: 'dataStores',
                key: 'dataStores',
                render: (_, record) => (
                    <div>
                        {record?.dataStores?.map(
                            (datastore: any, index: number) => (
                                <span key={index}>
                                    {index > 0 && ', '}
                                    {datastore.name}
                                </span>
                            )
                        )}
                    </div>
                ),
            },
            {
                title: 'Descriptions',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'right',
                render: (_, record) => (
                    <Can permission={CAN_MODIFY_DB_PARAMS} showTooltip={true}>
                        <DbParametersActionsLists
                            record={record}
                            refresh={refresh}
                        />
                    </Can>
                ),
            },
        ],
        []
    );

    return (
        <div className={styles.DbParametersTable}>
            <Row justify="space-between" className={styles.DbParametersHeader}>
                <Col className={styles.DbParametersTitle}>
                    <strong>DB parameter groups</strong>
                </Col>
                <Col>
                    <Row>
                        <Col
                            span={24}
                            className={styles.DbParametersTableHeader}
                        >
                            <Can
                                permission={CAN_ADD_DB_PARAMS}
                                showTooltip={false}
                            >
                                <CreateDbParameters
                                    buttonText="+ Create new group"
                                    refresh={refresh}
                                />
                            </Can>{' '}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isLoading ? (
                <AppLoading />
            ) : (
                <AppTable
                    columns={tableColumns}
                    data={tableData}
                    rowKey="id"
                    pagination={{
                        pageSize: pagination?.perPage,
                        current: pagination?.page,
                        total: pagination?.total,
                        hideOnSinglePage: true,
                    }}
                    onChange={onChangePage}
                />
            )}
        </div>
    );
};

export default DbParametersTable;
