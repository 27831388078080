import AppTable from '../../AppTable';
import { Button, Row, Col, Tag, notification } from 'antd';
import MembersActionsList, { MemberRecord } from './MembersActionsList';
import { useEffect, useMemo, useState } from 'react';
import AppLoading from '../../AppLoading';
import styles from './Members.module.less';
import MemberFormModal from './MemberFormModal';
import InviteMemberService from '../../../services/InvitesService';
import { useLocation } from 'react-router';
import useOrganizationInvites from '../../../core/hooks/useOrganizationInvites';
import moment from 'moment';
import useMembers from '../../../core/hooks/useMembers';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { CAN_ADD_MEMBERS } from '../../../core/PermissionRoles';
import Invite from '../../../types/Invite';
import Can from '../../../core/providers/Can';

interface MembersProps {
    testId?: string;
}

interface MembersAndInvitesTableInterface {
    id: string;
    user: string;
    status: string;
    role: string;
}

interface StatusConfig {
    color: 'error' | 'processing' | 'success';
    text: string;
}

const Members = ({ testId = 'Members' }: MembersProps) => {
    const { state: locationState } = useLocation<any>();
    const { user } = useAppSelector((state: RootState) => state.user);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { loading, refresh, members } = useMembers(
        locationState.organizationId
    );
    const { refresh: refreshInvites, invites } = useOrganizationInvites(
        locationState.organizationId
    );
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [membersAndInvitesTable, setMembersAndInvitesTable] = useState<
        MembersAndInvitesTableInterface[]
    >([]);
    const [isAddNewMemberModalVisible, setIsAddNewMemberModalVisible] =
        useState<boolean>(false);

    const getStatusConfig = (status: string): StatusConfig => {
        const configs: Record<string, StatusConfig> = {
            Expired: { color: 'error', text: 'Expired Invitation' },
            Declined: { color: 'error', text: 'Declined Invitation' },
            Pending: { color: 'processing', text: 'Pending Invitation' },
            Active: { color: 'success', text: 'Active' },
        };
        return configs[status] || configs.Active;
    };

    const handleAddNewMember = () => {
        setIsAddNewMemberModalVisible(true);
    };

    const handleClose = () => {
        setIsAddNewMemberModalVisible(false);
    };

    const handleSubmit = async (values: { email: string; role: string }) => {
        setIsLoading(true);
        try {
            await InviteMemberService.inviteNewMember(
                values,
                locationState.organizationId
            );
            refresh();
            refreshInvites();
            notification.success({
                message: 'Member Invited',
                description: 'The member has been invited successfully.',
            });
        } catch (e: any) {
            console.error(e);
            notification.error({
                message: 'Member invite failed',
                description: 'There was an error inviting the member.',
            });
        } finally {
            setIsAddNewMemberModalVisible(false);
            setIsLoading(false);
        }
    };

    const getStatus = (member: any): string => {
        if (member.expiresAt) {
            return moment(member.expiresAt).isAfter(moment())
                ? 'Pending'
                : 'Expired';
        }
        return member.isDeclined ? 'Declined' : 'Active';
    };

    const isUserAdmin = (tableArray: MembersAndInvitesTableInterface[]) => {
        if (members) {
            const admin = tableArray.find(
                (member: any) =>
                    member.role === 'Admin' &&
                    user &&
                    member.user.includes(user?.login || '')
            );
            setIsAdmin(!!admin);
        }
    };

    const filteredMembersList = useMemo(() => {
        if (!members) return [];
        const pendingInvites =
            invites?.invites.filter((invite: Invite) => !invite.acceptedAt) ||
            [];
        return [...members.members, ...pendingInvites];
    }, [members, invites]);

    const roles: Record<string, string> = {
        read: 'Read',
        write: 'Write',
        admin: 'Admin',
    };

    useEffect(() => {
        if (members) processTableData();
    }, [filteredMembersList, invites, members]);

    const processTableData = () => {
        const tableArray: MembersAndInvitesTableInterface[] = [];

        if (members) {
            filteredMembersList.forEach((member: any) => {
                tableArray.push({
                    id: member.id,
                    user: member.toUser ? member.toUser.email : member.name,
                    status: getStatus(member),
                    role: roles[member.role],
                });
            });
        }
        isUserAdmin(tableArray);
        setMembersAndInvitesTable(tableArray);
    };

    const columns = useMemo(
        () => [
            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
                align: 'left',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'left',
                render: (text: string) => {
                    const config = getStatusConfig(text);
                    return (
                        <Tag className={styles.MembersTag} color={config.color}>
                            {config.text}
                        </Tag>
                    );
                },
            },
            {
                title: 'Permission',
                dataIndex: 'role',
                key: 'role',
                align: 'left',
                render: (text: string) => <span>{text}</span>,
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'right',
                render: (_: string, record: MemberRecord) => (
                    <>
                        <MembersActionsList
                            orgId={locationState.organizationId}
                            record={record}
                            isAdmin={isAdmin}
                            refresh={refresh}
                            refreshInvites={refreshInvites}
                        />
                    </>
                ),
            },
        ],
        [locationState.organizationId, refresh, refreshInvites]
    );

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    return isLoading ? (
        <AppLoading />
    ) : (
        <div data-testid={testId} className={styles.Members}>
            <Row justify="end" className={styles.MembersHeaderButton}>
                {isAdmin && (
                    <Can permission={CAN_ADD_MEMBERS} showTooltip={false}>
                        <Button
                            type="primary"
                            onClick={() => handleAddNewMember()}
                        >
                            Add Member
                        </Button>
                    </Can>
                )}
            </Row>
            <Row className={styles.MembersTable}>
                <Col span={24}>
                    <AppTable
                        columns={columns}
                        data={membersAndInvitesTable}
                        rowKey="id"
                        pagination={{ pageSize: 10 }}
                    />
                </Col>
            </Row>
            <MemberFormModal
                visible={isAddNewMemberModalVisible}
                onClose={() => handleClose()}
                onSubmit={handleSubmit}
                isEditing={false}
                isLoading={isLoading}
            />
        </div>
    );
};

export default Members;
