import ApiService from './ApiService';
import { API_AUTH, ORGANIZATIONS, INVITES } from '../core/CcxEnv';
import Invites from '../types/Invites';

// constants
const API_URL = API_AUTH;

export default class InviteMemberService extends ApiService {
    /**
     * Get list of all invites for a given organization.
     * @param {string} uuid
     */
    static async getAllInvites(): Promise<any> {
        const response = await this.request('GET', INVITES);
        return new Invites(response);
    }

    /**
     * Get list of all invites for a given organization.
     * @param {string} uuid
     */
    static async getOrgInvites(orgId: string): Promise<any> {
        try {
            const response = await this.request(
                'GET',
                `${ORGANIZATIONS}/${orgId}/${INVITES}`
            );
            return new Invites(response);
        } catch (e) {
            console.error(e);
        }
    }

    static async inviteNewMember(
        member: {
            email: string;
            role: string;
        },
        orgId: string
    ): Promise<any> {
        const requestData = {
            data: {
                ...member,
            },
        };
        try {
            const response = await this.request(
                'POST',
                `${ORGANIZATIONS}/${orgId}/${INVITES}`,
                requestData
            );
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    static async acceptInvite(id: string): Promise<any> {
        const response = await this.request('POST', `${INVITES}/${id}/accept`);
        return response;
    }

    static async declineInvite(id: string): Promise<any> {
        const response = await this.request('POST', `${INVITES}/${id}/decline`);
        return response;
    }

    static async deleteInvite(orgId: string, inviteId: string): Promise<any> {
        const response = await this.request(
            'DELETE',
            `${ORGANIZATIONS}/${orgId}/${INVITES}/${inviteId}`
        );
        return response;
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
