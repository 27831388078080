import { ReactElement, useState } from 'react';
import { notification, Modal } from 'antd';
import styles from './Firewalls.module.less';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import useFirewalls from '../../core/hooks/useFirewalls';
import AppTable from '../AppTable';
import AppConfirmDialog from '../AppConfirmDialog';
import FirewallService from '../../services/FirewallService';
import AppLoading from '../AppLoading';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconDeleteOutlined from '../ccx/icons/CcxIconDeleteOutlined';
import DeploymentsItem from '../../types/DeploymentsItem';
import FirewallPortsTable from './FirewallsPortsTable';
import useIPAddress from '../../core/hooks/useIpAddress';
import FirewallHeader from './FirewallHeader';
import { FirewallTableInterface } from '../../types/FirewallTable';
import Can from '../../core/providers/Can';
import { CAN_DELETE_FIREWALL } from '../../core/PermissionRoles';

interface UrlProps {
    dataStoreUuid: string;
    testId?: string;
}

interface FirewallsProps {
    testId?: string;
    currentDeployment?: DeploymentsItem | null | undefined;
}

function Firewalls({
    testId = 'Firewalls',
    currentDeployment,
}: FirewallsProps): ReactElement {
    const { dataStoreUuid } = useParams<UrlProps>();
    useState<FirewallTableInterface[]>();
    const { firewalls, refresh, loading } = useFirewalls(dataStoreUuid);
    const { ipAddress } = useIPAddress();

    const columns = [
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (text: string, record: FirewallTableInterface) => {
                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        notification.open({
                            message: 'Remove trusted source',
                            description: 'The source will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            await FirewallService.deleteAllRule({
                                rule: record,
                                uuid: dataStoreUuid,
                            });

                            refresh();

                            notification.open({
                                message: 'Remove trusted source',
                                description: 'Source successfully removed',
                                icon: (
                                    <CcxIconCheckCircle twoToneColor="#52c41a" />
                                ),
                            });

                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove trusted source',
                                description: `There was an error removing the source. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            reject();
                        }
                    }).catch(() => console.log('Oops errors!'));
                };

                return (
                    <Can permission={CAN_DELETE_FIREWALL} showTooltip={true}>
                        <div className={styles.FirewallsOptionsColumn}>
                            <AppConfirmDialog
                                critical={true}
                                onOk={onConfirmDelete}
                                content="The source will be removed."
                                actionIcon={<CcxIconDeleteOutlined />}
                            />
                        </div>
                    </Can>
                );
            },
        },
    ];

    const rowExpandable = (record: FirewallTableInterface) => {
        return record.ports;
    };

    const expandedRowRenderFirewallTable = (record: FirewallTableInterface) => {
        return (
            <FirewallPortsTable
                rule={record}
                ports={record.ports}
                dataStoreUuid={dataStoreUuid}
                refresh={refresh}
            />
        );
    };

    return (
        <section data-testid={testId} className={styles.Firewalls}>
            <FirewallHeader
                ipAddress={ipAddress}
                refresh={refresh}
                dataStoreUuid={dataStoreUuid}
                currentDeployment={currentDeployment}
            />

            {loading ? (
                <AppLoading />
            ) : firewalls?.length ? (
                <div className={styles.FirewallPortsTable}>
                    <AppTable
                        columns={columns}
                        data={firewalls}
                        rowKey="source"
                        expandedRowRender={expandedRowRenderFirewallTable}
                        rowExpandable={rowExpandable}
                        customIcon={true}
                        pagination={{
                            hideOnSinglePage: true,
                        }}
                    />
                </div>
            ) : (
                <AppEmpty message="There are no firewall rules yet" />
            )}
        </section>
    );
}

export default Firewalls;
