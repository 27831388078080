export interface MemberInterface {
    id: string;
    name: string;
    role: string;
    type: string;
}

export interface MemberClassProps {
    id: string;
    name: string;
    role: string;
    type: string;
}

export default class Member implements MemberInterface {
    readonly id: string;
    readonly name: string;
    readonly role: string;
    readonly type: string;

    constructor(props: MemberClassProps) {
        this.id = props.id;
        this.name = props.name;
        this.role = props.role;
        this.type = props.type;
    }
}
