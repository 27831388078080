import { Modal, Form, Input, Button, Row, Col, Select } from 'antd';
import styles from './AddNewMemberModal.module.less';
import { useEffect, useState } from 'react';

interface MemberFormModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: any) => Promise<void>;
    record?: any;
    isEditing: boolean;
    testId?: string;
    isLoading?: boolean;
}

const MemberFormModal = ({
    record,
    visible,
    onClose,
    onSubmit,
    isEditing,
    isLoading,
    testId = 'MemberFormModal',
}: MemberFormModalProps) => {
    const [form] = Form.useForm();
    const [isValueUpdated, setIsValueUpdated] = useState(true);

    const handleFormSubmit = async (values: any) => {
        if (isEditing) {
            values = {
                ...values,
                userId: record.id,
            };
        }

        try {
            await form.validateFields();
            onSubmit({ ...values });
        } catch (error) {
            console.log('Failed to submit:', error);
            throw error;
        }
    };

    const formatEmail = (text: string) => {
        if (text.includes('(')) {
            return text.split('(')[1].split(')')[0];
        } else {
            return text;
        }
    };

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                email: formatEmail(record.user),
                role: record.role,
            });
        }
    }, [record, form]);

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <>
            <Modal
                title={isEditing ? `Update Member's rule` : 'Add New Member'}
                visible={visible}
                onCancel={onCancel}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                data-testid={`${testId}CancelButton`}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid={`${testId}SubmitButton`}
                                onClick={() =>
                                    handleFormSubmit(form.getFieldsValue())
                                }
                                type="primary"
                                loading={isLoading}
                                disabled={isValueUpdated}
                            >
                                {isEditing ? 'Update Role' : 'Send invite'}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={() => setIsValueUpdated(false)}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Please enter email to invite!',
                            },
                        ]}
                    >
                        <Input
                            className={styles.AddNewMemberModalInput}
                            placeholder="Member Name"
                            disabled={isEditing}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Role"
                        name="role"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a role!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Role"
                            className={styles.MemberRoleSelect}
                            onChange={(value) => {
                                form.setFieldValue('role', value);
                            }}
                        >
                            {['Read', 'Write'].map((role) => (
                                <Select.Option
                                    key={role}
                                    value={role.toLowerCase()}
                                >
                                    {role}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MemberFormModal;
