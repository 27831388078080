import { useState } from 'react';
import { Dropdown, Button, Menu, notification } from 'antd';
import DeleteOrganizationPopover from './DeleteOrganizationPopover';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import OrganizationModal from './OrganizationModal';
import OrganizationsService from '../../../services/OrganizationsService';
import {
    DeleteOutlined,
    EditOutlined,
    UserAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import styles from './OrganizationsActionsList.module.less';
import { userCheck } from '../../../slices/user.slice';
import { useAppDispatch } from '../../../redux/hooks';
import { Link } from 'react-router-dom';
import Organization from '../../../types/Organization';

export interface OrganizationRecord extends Organization {
    isAdmin: boolean;
    totalInvites: number;
    totalMembers: number;
}

interface OrganizationsActionsListProps {
    record: OrganizationRecord;
    refresh: () => void;
    setOrganizationData: React.Dispatch<
        React.SetStateAction<OrganizationRecord[]>
    >;
}

const OrganizationsActionsList = ({
    record,
    refresh,
    setOrganizationData,
}: OrganizationsActionsListProps) => {
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();

    const handleEdit = () => {
        if (!isEditVisible) setIsEditVisible(true);
    };

    const handleDelete = () => {
        if (!isDeleteVisible) setIsDeleteVisible(true);
    };

    const handleUpdateOrganization = async (values: { name: string }) => {
        setIsLoading(true);
        try {
            await OrganizationsService.updateOrganization({
                id: record.id,
                name: values.name,
            });
            notification.success({
                message: 'Organization Updated',
                description: 'The organization has been updated successfully.',
            });
            dispatch(userCheck());
        } catch (e: any) {
            notification.error({
                message: 'Organization Update Failed',
                description: 'There was an error updating the organization.',
            });
        } finally {
            setIsLoading(false);
            setIsEditVisible(false);
            refresh();
        }
    };

    const handleDeleteOrganization = async () => {
        setIsLoading(true);
        try {
            await OrganizationsService.deleteOrganization({
                id: record.id,
            });
            setOrganizationData((prev: OrganizationRecord[]) =>
                prev.filter((org) => org.id !== record.id)
            );
            notification.success({
                message: 'Organization Deleted',
                description: 'The organization has been deleted successfully.',
            });
            refresh();
            dispatch(userCheck());
        } catch (e: any) {
            notification.error({
                message: 'Organization Delete Failed',
                description: 'There was an error deleting the organization.',
            });
        } finally {
            setIsLoading(false);
        }
    };
    const handleClose = () => {
        setIsEditVisible(false);
    };

    const menu = (
        <Menu>
            {record.isAdmin && (
                <Menu.Item key="edit" onClick={handleEdit}>
                    {' '}
                    <Button
                        type="link"
                        className={styles.OrganizationActionButton}
                        icon={<EditOutlined />}
                    >
                        Edit
                    </Button>
                </Menu.Item>
            )}
            <Menu.Item key="users">
                <Link
                    to={{
                        pathname: `/organizations/${record.id}/users`,
                        state: {
                            organizationId: record.id,
                            organizationName: record.name,
                        },
                    }}
                >
                    <Button
                        type="link"
                        className={styles.OrganizationActionButton}
                        icon={<UserOutlined />}
                    >
                        Manage Users
                    </Button>
                </Link>
            </Menu.Item>
            {record.isAdmin && (
                <Menu.Item key="delete" onClick={handleDelete}>
                    <Button
                        type="link"
                        className={styles.DeleteOrganizationPopoverButton}
                        icon={<DeleteOutlined />}
                    >
                        Delete
                    </Button>
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <>
            <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
            >
                <Button loading={isLoading} icon={<CcxContextualMenuIcon />} />
            </Dropdown>

            <OrganizationModal
                visible={isEditVisible}
                onClose={handleClose}
                record={record}
                isEditing={true}
                onSubmit={handleUpdateOrganization}
                testId="EditOrganizationModal"
                isLoading={isLoading}
            />

            <DeleteOrganizationPopover
                isDeleteVisible={isDeleteVisible}
                setIsDeleteVisible={setIsDeleteVisible}
                handleOk={() => handleDeleteOrganization()}
            />
        </>
    );
};

export default OrganizationsActionsList;
