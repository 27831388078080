export type InviteClassProps = {
    created_at: string;
    declined_at: string;
    accepted_at: string;
    expires_at: string;
    from_user: {
        email: string;
        id: string;
        first_name: string;
        last_name: string;
    };
    to_user: {
        email: string;
        id: string;
        first_name: string;
        last_name: string;
    };
    organization: {
        id: string;
        name: string;
    };
    id: string;
    role: Role;
};

interface InviteUserDetails {
    email: string;
    id: string;
    firstName: string;
    lastName: string;
}

interface InviteOrganizationDetails {
    id: string;
    name: string;
}

enum Role {
    read = 'read',
    write = 'write',
    admin = 'admin',
}

export interface InviteInterface {
    createdAt: string;
    expiresAt: string;
    declinedAt: string;
    acceptedAt: string;
    fromUser: InviteUserDetails;
    toUser: InviteUserDetails;
    organization: InviteOrganizationDetails;
    role: Role;
    id: string;
}

export default class Invite implements InviteInterface {
    readonly createdAt: string;
    readonly declinedAt: string;
    readonly acceptedAt: string;
    readonly expiresAt: string;
    readonly fromUser: InviteUserDetails;
    readonly toUser: InviteUserDetails;
    readonly organization: InviteOrganizationDetails;
    readonly id: string;
    readonly role: Role;

    constructor(props: InviteClassProps) {
        this.createdAt = props.created_at;
        this.declinedAt = props.declined_at;
        this.acceptedAt = props.accepted_at;
        this.expiresAt = props.expires_at;
        this.fromUser = {
            email: props.from_user.email,
            id: props.from_user.id,
            firstName: props.from_user.first_name,
            lastName: props.from_user.last_name,
        };
        this.toUser = {
            email: props.to_user.email,
            id: props.to_user.id,
            firstName: props.to_user.first_name,
            lastName: props.to_user.last_name,
        };
        this.organization = {
            id: props.organization.id,
            name: props.organization.name,
        };
        this.id = props.id;
        this.role = props.role;
    }
}
