import { Tabs } from 'antd';
import { ReactElement } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './Organization.module.less';
import Organizations from './organizationInfo/Organizations';

interface Props extends CcxComponentProps {}

function Organization({ testId = 'Organization' }: Props): ReactElement {
    return (
        <section className={styles.Organization} data-testid={testId}>
            <h1 data-testid={`${testId}Title`}>
                <strong>Manage Organizations And Users</strong>
            </h1>

            <Organizations />
        </section>
    );
}

export default Organization;
