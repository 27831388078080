import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import DeploymentsItem from '../../types/DeploymentsItem';
import DbUser from '../../types/DbUser';
import UpdateDbUserDrawer from './UpdateDbUserDrawer';
import AppConfirmDialog from '../AppConfirmDialog';
import React from 'react';
import {
    DeleteOutlined,
    EditOutlined,
} from '@severalnines/frontend_hub/libs/icons';
import { useRBAC } from '../../core/hooks/useRBAC';
import {
    CAN_DELETE_DATABASE_USER,
    CAN_MODIFY_DATABASE_USERS,
} from '../../core/PermissionRoles';
import Can from '../../core/providers/Can';

type UpdateDbUserActionsMenuProps = {
    disabled?: boolean;
    deployment: DeploymentsItem;
    user: DbUser;
    onConfirmDelete: () => void;
    uuid: string;
    dbUsersRefresh: Function;
    databases?: any | undefined;
};
export default function UpdateDbUserActionsMenu({
    disabled,
    deployment,
    user,
    onConfirmDelete,
    uuid,
    dbUsersRefresh,
    databases,
}: UpdateDbUserActionsMenuProps) {
    const { hasPermission } = useRBAC();

    const menu = (
        <Menu>
            <Can permission={CAN_MODIFY_DATABASE_USERS} showTooltip={false}>
                <Menu.Item key="edit" icon={<EditOutlined />}>
                    <UpdateDbUserDrawer
                        currentDeployment={deployment}
                        user={user}
                        onSuccess={dbUsersRefresh}
                        uuid={uuid}
                        databases={databases}
                    />
                    Update
                </Menu.Item>
                <Menu.Divider />
            </Can>
            <Can permission={CAN_DELETE_DATABASE_USER} showTooltip={false}>
                <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={onConfirmDelete}
                >
                    Delete
                </Menu.Item>
            </Can>
        </Menu>
    );
    return hasPermission(CAN_MODIFY_DATABASE_USERS) ||
        hasPermission(CAN_DELETE_DATABASE_USER) ? (
        <Dropdown
            overlay={menu}
            disabled={disabled}
            placement="bottomRight"
            trigger={['click']}
        >
            <Button icon={<CcxContextualMenuIcon />} />
        </Dropdown>
    ) : (
        <Tooltip title="You do not have permission to perform this action.">
            <Button icon={<CcxContextualMenuIcon />} />
        </Tooltip>
    );
}
