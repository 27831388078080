import { useEffect, useState } from 'react';
import MembersService from '../../services/MembersService';
import Members from '../../types/Members';

export default function useMembers(orgId: string) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [members, setMembers] = useState<Members>();

    const load = async () => {
        try {
            const response = await MembersService.getAllMembers(orgId);
            setMembers(response);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { members, refresh, loading, updating, error };
}
