export const CAN_ADD_VPC = 'canAddVpc';
export const CAN_INVITES = 'canInvites';
export const CAN_SEE_INVITES = 'canSeeInvites';
export const CAN_ADD_MEMBERS = 'canAddMembers';
export const CAN_ADD_FIREWALL = 'canAddFirewall';
export const CAN_ADD_DATABASE = 'canAddDatabase';
export const CAN_ADD_DATASTORE = 'canAddDatastore';
export const CAN_ADD_DB_PARAMS = 'canCreateDbParams';
export const CAN_ADD_DATABASE_USER = 'canAddDatabaseUser';
export const CAN_RESTORE_DATASTORE = 'canRestoreDatastore';

export const CAN_MODIFY_VPC = 'canModifyVpc';
export const CAN_MODIFY_DB_PARAMS = 'canModifyDbParams';
export const CAN_MODIFY_DATASTORE = 'canModifyDatastore';
export const CAN_MODIFY_ORG_MEMBERS = 'canModifyOrgMembers';
export const CAN_MODIFY_ORGANIZATION = 'canModifyOrganization';
export const CAN_MODIFY_DATABASE_USERS = 'canModifyDatabaseUsers';
export const CAN_MODIFY_BACKUP_SETTINGS = 'canModifyBackupSettings';
export const CAN_MODIFY_BACKUP_SCHEDULE = 'canModifyBackupSchedule';
export const CAN_MODIFY_DATASTORE_SETTINGS = 'canModifyDatastoreSettings';

export const CAN_DELETE_VPC = 'canDeleteVPC';
export const CAN_DELETE_MEMBER = 'canDeleteMember';
export const CAN_DELETE_INVITES = 'canDeleteInvites';
export const CAN_DELETE_DATABASE = 'canDeleteDatabase';
export const CAN_DELETE_DB_PARAMS = 'canDeleteDbParams';
export const CAN_DELETE_DATASTORE = 'canDeleteDatastore';
export const CAN_DELETE_ORGANIZATION = 'canDeleteOrganization';
export const CAN_DELETE_DATABASE_USER = 'canDeleteDatabaseUser';
export const CAN_DELETE_FIREWALL = 'canDeleteFirewall';

export type Permission =
    | typeof CAN_ADD_DATASTORE
    | typeof CAN_ADD_VPC
    | typeof CAN_ADD_MEMBERS
    | typeof CAN_ADD_DATABASE
    | typeof CAN_ADD_DATABASE_USER
    | typeof CAN_MODIFY_DATASTORE
    | typeof CAN_MODIFY_DB_PARAMS
    | typeof CAN_MODIFY_VPC
    | typeof CAN_MODIFY_DATASTORE
    | typeof CAN_INVITES
    | typeof CAN_SEE_INVITES
    | typeof CAN_RESTORE_DATASTORE
    | typeof CAN_DELETE_VPC
    | typeof CAN_ADD_DB_PARAMS
    | typeof CAN_MODIFY_ORGANIZATION
    | typeof CAN_MODIFY_ORG_MEMBERS
    | typeof CAN_MODIFY_DATABASE_USERS
    | typeof CAN_DELETE_DB_PARAMS
    | typeof CAN_DELETE_ORGANIZATION
    | typeof CAN_DELETE_DATASTORE
    | typeof CAN_DELETE_MEMBER
    | typeof CAN_DELETE_DATABASE_USER
    | typeof CAN_DELETE_DATABASE
    | typeof CAN_DELETE_INVITES
    | typeof CAN_MODIFY_BACKUP_SETTINGS
    | typeof CAN_MODIFY_DATASTORE_SETTINGS
    | typeof CAN_MODIFY_BACKUP_SCHEDULE
    | typeof CAN_ADD_FIREWALL
    | typeof CAN_DELETE_FIREWALL;

export type Roles = {
    [K in Permission]?: boolean;
};

export interface PermissionRolesInterface {
    read: Roles;
    write: Roles;
    admin: Roles;
}

export const PermissionRoles: PermissionRolesInterface = {
    read: {},
    write: {
        [CAN_ADD_DATABASE]: true,
        [CAN_ADD_DATABASE_USER]: true,
        [CAN_ADD_MEMBERS]: true,
        [CAN_DELETE_DATABASE]: true,
        [CAN_DELETE_DATABASE_USER]: true,
        [CAN_MODIFY_VPC]: true,
        [CAN_MODIFY_DATASTORE]: true,
        [CAN_MODIFY_DB_PARAMS]: true,
        [CAN_MODIFY_ORGANIZATION]: true,
        [CAN_MODIFY_DATABASE_USERS]: true,
        [CAN_MODIFY_DATASTORE_SETTINGS]: true,
    },
    admin: {
        [CAN_ADD_VPC]: true,
        [CAN_ADD_MEMBERS]: true,
        [CAN_ADD_DATABASE]: true,
        [CAN_ADD_DATASTORE]: true,
        [CAN_ADD_DATABASE_USER]: true,
        [CAN_DELETE_MEMBER]: true,
        [CAN_DELETE_DATASTORE]: true,
        [CAN_MODIFY_DATASTORE]: true,
        [CAN_MODIFY_DB_PARAMS]: true,
        [CAN_MODIFY_VPC]: true,
        [CAN_DELETE_DATABASE_USER]: true,
        [CAN_DELETE_DATABASE]: true,
        [CAN_DELETE_DB_PARAMS]: true,
        [CAN_DELETE_VPC]: true,
        [CAN_DELETE_ORGANIZATION]: true,
        [CAN_RESTORE_DATASTORE]: true,
        [CAN_INVITES]: true,
        [CAN_SEE_INVITES]: true,
        [CAN_MODIFY_ORGANIZATION]: true,
        [CAN_MODIFY_DATABASE_USERS]: true,
        [CAN_MODIFY_ORG_MEMBERS]: true,
        [CAN_ADD_DB_PARAMS]: true,
        [CAN_DELETE_INVITES]: true,
        [CAN_MODIFY_BACKUP_SETTINGS]: true,
        [CAN_MODIFY_DATASTORE_SETTINGS]: true,
        [CAN_MODIFY_BACKUP_SCHEDULE]: true,
        [CAN_ADD_FIREWALL]: true,
        [CAN_DELETE_FIREWALL]: true,
    },
};
