import AppTable from '../../AppTable';
import { Button, Row, Col, notification, Tag } from 'antd';
import OrganizationsActionsList, {
    OrganizationRecord,
} from './OrganizationsActionsList';
import { useEffect, useMemo, useState } from 'react';
import useOrganization from '../../../core/hooks/UseOrganizations';
import AppLoading from '../../AppLoading';
import OrganizationsService from '../../../services/OrganizationsService';
import OrganizationModal from './OrganizationModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { OrganizationInterface } from '../../../types/Organization';
import { userCheck } from '../../../slices/user.slice';
import styles from './Organizations.module.less';
import InviteMemberService from '../../../services/InvitesService';
import MembersService from '../../../services/MembersService';
import { RootState } from '../../../redux/store';
import { MemberInterface } from '../../../types/Member';
import Invites from '../../../types/Invites';

interface OrganizationInfoProps {
    testId?: string;
}

interface OrganizationDataItem extends OrganizationInterface {
    isAdmin: boolean;
    totalMembers: number;
    totalInvites: number;
}

const Organizations = ({
    testId = 'OrganizationInfo',
}: OrganizationInfoProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { loading, refresh, organization } = useOrganization();
    const { user } = useAppSelector((state: RootState) => state.user);
    const [isAddingOrganization, setIsAddingOrganization] =
        useState<boolean>(false);
    const [organizationData, setOrganizationData] = useState<
        OrganizationDataItem[]
    >([]);
    const dispatch = useAppDispatch();
    const [isAddOrganizationModalVisible, setIsAddOrganizationModalVisible] =
        useState<boolean>(false);

    const columns = useMemo(
        () => [
            { title: 'Name', dataIndex: 'name', key: 'name', align: 'left' },
            {
                title: 'Members',
                dataIndex: 'totalMembers',
                key: 'totalMembers',
                align: 'left',
                render: (_: string, record: any) => (
                    <>
                        <Tag className={styles.MembersTag} color="success">
                            {record.totalMembers} Active
                        </Tag>
                        {record.totalInvites !== 0 && (
                            <Tag
                                className={styles.MembersTag}
                                color="processing"
                            >
                                {record.totalInvites} Pending Invitations
                            </Tag>
                        )}
                    </>
                ),
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'right',
                render: (_: string, record: OrganizationRecord) => (
                    <>
                        <OrganizationsActionsList
                            record={record}
                            refresh={refresh}
                            setOrganizationData={setOrganizationData}
                        />
                    </>
                ),
            },
        ],
        [refresh]
    );

    const AddNewOrganization = async (org: {
        name: string;
        identifier: string;
    }) => {
        setIsAddingOrganization(true);
        try {
            await OrganizationsService.addNewOrganization({
                id: org.identifier,
                name: org.name,
            });
            notification.success({
                message: 'Organization Added',
                description: 'The organization has been added successfully.',
            });
            dispatch(userCheck());
        } catch (e: any) {
            notification.error({
                message: 'Organization Add Failed',
                description: 'There was an error adding the organization.',
            });
        } finally {
            refresh();
            setIsAddingOrganization(false);
            setIsAddOrganizationModalVisible(false);
            setIsLoading(false);
        }
    };

    const handleAddOrganization = () => {
        setIsAddOrganizationModalVisible(true);
    };

    const getOrganizationData = async () => {
        try {
            setIsLoading(true);

            await Promise.any(
                organization?.organizations.map(
                    async (org: OrganizationInterface) => {
                        let invitesCount = 0;
                        let membersCount = 0;
                        let isAdmin = false;
                        let members: any;
                        try {
                            members = await MembersService.getAllMembers(
                                org.id
                            );
                            membersCount += members.total;
                            isAdmin = !!members.members.find(
                                (member: MemberInterface) =>
                                    member.role === 'admin' &&
                                    user &&
                                    member.id === user.id
                            );
                        } catch {
                            membersCount += 0;
                        }

                        try {
                            const invites: Invites =
                                await InviteMemberService.getOrgInvites(org.id);
                            invitesCount =
                                invites.getAllPendingInvites().length;
                        } catch {
                            invitesCount += 0;
                        }
                        const isInOrgList = organizationData.find(
                            (o: any) => o.id === org.id
                        );
                        if (!isInOrgList) {
                            organizationData.push({
                                ...org,
                                isAdmin,
                                totalMembers: membersCount,
                                totalInvites: invitesCount,
                            });
                            setOrganizationData([...organizationData]);
                        }
                    }
                )
            );
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getOrganizationData();
    }, [organization]);

    return isLoading || loading ? (
        <AppLoading />
    ) : (
        <div data-testid={testId} className={styles.Organizations}>
            <Row justify="end" className={styles.OrganizationsHeaderButton}>
                <Button type="primary" onClick={handleAddOrganization}>
                    Add Organization
                </Button>
            </Row>
            <Row className={styles.OrganizationsTable}>
                <Col span={24}>
                    <AppTable
                        columns={columns}
                        data={organizationData}
                        rowKey="key"
                        pagination={{ pageSize: 10 }}
                    />
                </Col>
            </Row>
            <OrganizationModal
                record={organization}
                visible={isAddOrganizationModalVisible}
                onClose={() => setIsAddOrganizationModalVisible(false)}
                onSubmit={AddNewOrganization}
                isEditing={false}
                isLoading={isAddingOrganization}
                testId="OrganizationModal"
            />
        </div>
    );
};

export default Organizations;
