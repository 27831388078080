import { useEffect, useMemo, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Dropdown,
    Form,
    Menu,
    Modal,
    Row,
    Tooltip,
    message,
    notification,
} from 'antd';
import { billingDisabled } from '../../../../core/CcxEnv';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PrettyNumber from '../../../ccx/common/PrettyNumber';
import {
    CompressOutlined,
    InfoCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import DatastoreScalingInfoCard from '../DatastoreScalingInfoCard';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import styles from './DataStoreNodeConfiguration.module.less';
import DatastoreVolumeCard from './DatastoreVolumeCard';
import DatastoreScaling from '../DatastoreScaling';
import ProvisionService from '../../../../services/ProvisionService';
import CcxIconInfoCircleTwoTone from '../../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import { useParams } from 'react-router';
import { getNonErroredJobs } from '../../../../core/helpers';
import InstanceVolumeSize from '../../../../types/InstanceVolumeSize';
import InstanceVolumeType from '../../../../types/InstanceVolumeType';
import { useAppSelector } from '../../../../redux/hooks';
import { CAN_MODIFY_DATASTORE } from '../../../../core/PermissionRoles';
import Can from '../../../../core/providers/Can';

interface DataStoreNodeConfigurationProps {
    readonly testId?: string;
    readonly setIsScalingModalVisible: Function;
    readonly dataStore: DeploymentsItem | undefined;
    readonly services: any;
    readonly clearStoreInterval: Function;
}

export type DatastoreConfigurations = {
    iops: number | undefined;
    storage: number | undefined;
    storageType: string | undefined;
};

export type SelectOptions = {
    volumeTypes: InstanceVolumeType[];
    volumeSizes: InstanceVolumeSize[];
};

interface UrlProps {
    dataStoreUuid?: string;
}

function DataStoreNodeConfiguration({
    services,
    dataStore,
    clearStoreInterval,
    setIsScalingModalVisible,
    testId = 'DataStoreNodeConfigurationModal',
}: DataStoreNodeConfigurationProps) {
    let { dataStoreUuid } = useParams<UrlProps>();
    const [form] = Form.useForm();
    const [isVisible, setIsVisible] = useState<string | null>(null);
    const [monthlyCost, setMonthlyCost] = useState(0);
    const [countryCode, setCountryCode] = useState<string | undefined>(
        undefined
    );
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [configuration, setConfiguration] =
        useState<DatastoreConfigurations>();
    const { jobs } = useDataStoreJobs({
        dataStoreUuid,
    });
    const pendingJobs = getNonErroredJobs(jobs);

    const [selectOptions, setSelectOptions] = useState<SelectOptions>({
        volumeTypes: [],
        volumeSizes: [],
    });
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    const volumeSize = useMemo(() => {
        if (deploymentOptions && dataStore) {
            const code = dataStore.cloudProvider.code;
            const volumeType = dataStore.volumeType;
            return deploymentOptions.instance.volume_sizes[code][volumeType];
        }
        return null;
    }, [deploymentOptions, dataStore]);

    useEffect(() => {
        processOptions();
        if (dataStore) {
            setConfiguration({
                storage: dataStore.volumeSize,
                storageType: dataStore.volumeType,
                iops: dataStore.volumeIops === 0 ? 1000 : dataStore.volumeIops,
            });
        }
    }, [deploymentOptions]);

    const processOptions = () => {
        let volumeSizes: InstanceVolumeSize[] = [];
        let volumeTypes: InstanceVolumeType[] = [];
        if (deploymentOptions && dataStore) {
            const code = dataStore.cloudProvider.code;
            volumeTypes = deploymentOptions.instance.volumeTypes[code].filter(
                (type: any) => type.code !== ''
            );
            volumeSizes = deploymentOptions.instance.volume_sizes[code];
        }
        setSelectOptions({ volumeSizes, volumeTypes });
    };

    const getVolumeTypePrice = () => {
        if (!dataStore) {
            return;
        }
        const cloudProviderCode = dataStore?.cloudProvider?.code;
        const volumeTypes =
            deploymentOptions?.instance?.volumeTypes?.[cloudProviderCode];

        const volumeTypePrice = volumeTypes?.find(
            (volumeType: any) => volumeType.code === configuration?.storageType
        )?.price;

        return volumeTypePrice / 100;
    };

    useEffect(() => {
        const numberOfNodes = services?.dbServices?.length;
        let instanceNumber = numberOfNodes || 0;
        let storageMonthlyPrice = getVolumeTypePrice() ?? 0;
        let numberOfGB = configuration?.storage ?? 0;
        let iopsMonthlyPrice = 0;
        let numberOfIOPS = 0;

        let monthlyPriceEstimate =
            storageMonthlyPrice * instanceNumber * numberOfGB +
            iopsMonthlyPrice * instanceNumber * numberOfIOPS;
        setMonthlyCost(parseFloat(monthlyPriceEstimate.toFixed(2)));
    }, [getVolumeTypePrice, configuration, services?.dbServices?.length]);

    const onSubmitForm = async () => {
        await upgradeStorage();
    };

    const upgradeStorage = async () => {
        if (!dataStore) return;

        const isUpgradeStorageValid =
            (configuration?.storage ?? 0) < (dataStore?.volumeSize ?? 0) + 10;
        if (form.isFieldTouched('volumeSize') && isUpgradeStorageValid) {
            message.error(
                'New storage must be at least 10 GB greater than current storage.'
            );
            return;
        }
        const data = processData();
        if (data) {
            try {
                await ProvisionService.updateDatastoreStorage({
                    dataStoreUuid: dataStore?.getUUID(),
                    data,
                });
                successNotification();
            } catch (error) {
                notification.open({
                    message: 'Datastore storage',
                    description: `There was an error trying to upgrading. ${error}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
            }
            setIsVisible(null);
        }
    };

    const processData = () => {
        if (!dataStore) return;
        const isStorageTypeSame =
            dataStore.volumeType === configuration?.storageType;
        const isStorageIops = configuration?.storageType === 'io1';
        if (isStorageTypeSame) {
            return {
                new_volume_size: configuration.storage,
                new_volume_iops:
                    configuration?.storageType === 'io1'
                        ? configuration?.iops
                        : undefined,
            };
        } else if (!isStorageTypeSame && isStorageIops) {
            return {
                change_volume: {
                    new_volume_type: configuration?.storageType,
                    new_volume_iops: configuration?.iops,
                },
            };
        } else if (
            !isStorageTypeSame &&
            !isStorageIops &&
            !form.isFieldTouched('volumeSize')
        ) {
            return {
                change_volume: {
                    new_volume_type: configuration?.storageType,
                },
            };
        } else {
            return {
                change_volume: {
                    new_volume_size: configuration?.storage,
                    new_volume_type: configuration?.storageType,
                    new_volume_iops:
                        configuration?.storageType === 'io1'
                            ? configuration.iops
                            : undefined,
                },
            };
        }
    };

    const successNotification = () => {
        notification.open({
            message: 'Datastore storage',
            description: 'Your datastore storage will be updated shortly.',
            icon: <CcxIconInfoCircleTwoTone />,
        });
        setIsVisible(null);
    };

    const onChangeConfigurations = (value: DatastoreConfigurations) => {
        form.setFieldsValue(value);
        setConfiguration(value);
        if (
            dataStore?.volumeSize !== value.storage ||
            dataStore?.volumeType !== value.storageType ||
            dataStore?.volumeIops !== value.iops
        ) {
            setIsButtonDisabled(false);
            return;
        }
        setIsButtonDisabled(true);
    };

    const closeModal = async () => {
        setIsVisible(null);
        form.setFieldsValue({
            volumeSize: dataStore?.volumeSize,
            volumeIops: dataStore?.volumeIops,
        });
        setConfiguration({
            storage: dataStore?.volumeSize,
            storageType: dataStore?.volumeType,
            iops: dataStore?.volumeIops,
        });
    };

    const openScaleModal = () => {
        clearStoreInterval();
        setIsScalingModalVisible(true);
    };

    const showNodesConfiguration =
        dataStore?.canChangeVolumeType() || dataStore?.canScaleNodes();
    return (
        <>
            {showNodesConfiguration && (
                <>
                    <Can permission={CAN_MODIFY_DATASTORE} showTooltip={false}>
                        <Dropdown
                            disabled={
                                pendingJobs?.length > 0 ||
                                !dataStore?.isOperable()
                            }
                            overlay={
                                <Menu>
                                    {dataStore?.canChangeVolumeType() && (
                                        <Menu.Item
                                            key="size"
                                            onClick={(e) => setIsVisible(e.key)}
                                        >
                                            <SaveOutlined /> Edit Storage
                                        </Menu.Item>
                                    )}
                                    {dataStore?.canScaleNodes() && (
                                        <Menu.Item
                                            key="nodes"
                                            onClick={openScaleModal}
                                        >
                                            <CompressOutlined /> Scale Nodes
                                        </Menu.Item>
                                    )}
                                </Menu>
                            }
                            placement="bottomRight"
                            trigger={['click']}
                        >
                            <Button
                                disabled={true}
                                data-testid={`${testId}Dropdown`}
                                style={{ padding: '1px 8px 1px 8px' }}
                            >
                                Nodes Configurations
                            </Button>
                        </Dropdown>
                    </Can>
                    <Modal
                        title={'Edit storage'}
                        open={!!isVisible}
                        data-testid={`${testId}Modal`}
                        width={600}
                        onCancel={closeModal}
                        footer={
                            <Row
                                justify={
                                    billingDisabled ? 'end' : 'space-between'
                                }
                            >
                                {!billingDisabled && (
                                    <Col>
                                        <div>
                                            <div>
                                                Estimated total cost{' '}
                                                <InfoIcon
                                                    info={
                                                        <span>
                                                            This amount will be
                                                            added to your next
                                                            invoice.
                                                        </span>
                                                    }
                                                />{' '}
                                            </div>
                                            <div
                                                style={{
                                                    color: '#C41D7F',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                }}
                                            >
                                                <PrettyNumber
                                                    prefix={<>&#36;</>}
                                                    value={monthlyCost}
                                                    sufix={' / month'}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                )}
                                <Col>
                                    <Button
                                        data-testid={`${testId}CancelButton`}
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={isButtonDisabled}
                                        data-testid={`${testId}SubmitButton`}
                                        onClick={() => onSubmitForm()}
                                        type="primary"
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        }
                    >
                        <Form form={form}>
                            <DatastoreScalingInfoCard
                                dataStore={dataStore}
                                setCountryCode={setCountryCode}
                                countryCode={countryCode}
                            />
                            <DatastoreVolumeCard
                                configuration={configuration}
                                selectOptions={selectOptions}
                                onChangeConfigurations={onChangeConfigurations}
                            />
                            {dataStore && !dataStore?.isEphemeral() && (
                                <DatastoreScaling
                                    showSubtitle={false}
                                    dataStore={dataStore}
                                    volumeSize={volumeSize}
                                    configuration={configuration}
                                    onChangeConfigurations={
                                        onChangeConfigurations
                                    }
                                />
                            )}
                        </Form>

                        <Col span={24}>
                            <Alert
                                className={styles.DatastoreScalingStep2Alert}
                                message={
                                    <>
                                        The update will be performed using a
                                        roll-forward upgrade algorithm{' '}
                                        <Tooltip
                                            title={
                                                <div>
                                                    <strong>
                                                        The update will be
                                                        performed using a
                                                        roll-forward upgrade
                                                        algorithm with the
                                                        following steps:
                                                    </strong>
                                                    <ol>
                                                        <li>
                                                            The oldest replica
                                                            (or primary if no
                                                            replica exists) will
                                                            be selected first;
                                                        </li>
                                                        <li>
                                                            A new node will be
                                                            added with the same
                                                            specification as the
                                                            oldest node and join
                                                            the datastore;
                                                        </li>
                                                        <li>
                                                            The oldest node will
                                                            be removed;
                                                        </li>
                                                        <li>
                                                            If it is a
                                                            primary-replica
                                                            configuration then
                                                            the primary will be
                                                            updated last. A new
                                                            node will be added,
                                                            the new node will be
                                                            promoted to become
                                                            the new primary, and
                                                            the old primary will
                                                            be removed.
                                                        </li>
                                                    </ol>
                                                </div>
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </>
                                }
                                showIcon
                                type="info"
                            />
                        </Col>
                    </Modal>
                </>
            )}
        </>
    );
}

export default DataStoreNodeConfiguration;
