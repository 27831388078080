import { InfoCircleFilled } from '@ant-design/icons';
import { Popover, Space, Row, Col, Button } from 'antd';
import styles from './DeleteOrganizationPopover.module.less';

interface DeleteOrganizationPopoverProps {
    handleOk: () => void;
    isDeleteVisible: boolean;
    setIsDeleteVisible: React.Dispatch<React.SetStateAction<boolean>>;
    testId?: string;
}

const DeleteOrganizationPopover = ({
    handleOk,
    isDeleteVisible,
    setIsDeleteVisible,
    testId = 'DeleteOrganizationPopover',
}: DeleteOrganizationPopoverProps) => {
    const handleSubmit = () => {
        setIsDeleteVisible(false);
        handleOk();
    };

    return (
        <Popover
            data-testid={testId}
            open={isDeleteVisible}
            placement="left"
            content={
                <>
                    <Row justify={'end'} className={styles.PopoverContentRow}>
                        <Col span={2}>
                            <InfoCircleFilled
                                className={styles.ExclamationCircleFilledColor}
                            />
                        </Col>
                        <Col span={22}>
                            <div>Are you sure?</div>
                            <div>
                                Are you sure you want to delete this
                                organization?
                            </div>
                        </Col>
                    </Row>

                    <Row justify={'end'} className={styles.PopoverActionsRow}>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSubmit()}
                            >
                                Yes
                            </Button>
                            <Button onClick={() => setIsDeleteVisible(false)}>
                                No
                            </Button>
                        </Space>
                    </Row>
                </>
            }
            trigger="click"
            onOpenChange={() => setIsDeleteVisible(!isDeleteVisible)}
        ></Popover>
    );
};

export default DeleteOrganizationPopover;
