import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ORGANIZATIONS_PAGE_HEADING } from '../../../core/CcxEnv';
import styles from './OrganizationMembers.module.less';
import Members from './Members';

interface OrganizationMembersProps {
    testId?: string;
}

const OrganizationMembers = ({
    testId = 'OrganizationMembers',
}: OrganizationMembersProps): JSX.Element => {
    const { state: locationState } = useLocation<any>();

    return (
        <section className={styles.OrganizationMembers} data-testid={testId}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={`/organizations`}>
                        {ORGANIZATIONS_PAGE_HEADING}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {locationState.organizationName}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Members />
        </section>
    );
};

export default OrganizationMembers;
