import BackupsFormModal, { BackupsFormModalProps } from './BackupsFormModal';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import DeploymentsItem from '../../types/DeploymentsItem';
import BackupSchedule from '../../types/BackupSchedule';
import BackupsScheduleEditIncrementalForm from './BackupsScheduleEditIncrementalForm';
import BackupsScheduleEditFullForm from './BackupsScheduleEditFullForm';
import { Draft } from '@reduxjs/toolkit';
import useDataStore from '../../core/hooks/useDataStore';
import { SelectFieldOptions } from '../../core/helpers';

type BackupsScheduleEditFullDrawerProps = Omit<
    BackupsFormModalProps,
    'form'
> & {
    deployment: Draft<DeploymentsItem>;
    schedule: BackupSchedule;
    onSuccess?: () => void;
};

export default function BackupsScheduleEditDrawer({
    deployment,
    schedule,
    onSuccess,
    ...rest
}: BackupsScheduleEditFullDrawerProps) {
    const [nodesOptions, setNodesOptions] = useState<SelectFieldOptions[]>([]);
    const { dataStore } = useDataStore(deployment?.dataStoreUuid);
    const [form] = useForm();

    useEffect(() => {
        let nodesOptions: SelectFieldOptions[] =
            dataStore?.getDbNodes().map((n: any) => {
                return {
                    value: `${n.hostUuid}`,
                    label: n.getFqdnPortWithRole(),
                };
            }) || [];
        setNodesOptions(nodesOptions);
    }, [dataStore]);

    return (
        <BackupsFormModal
            form={form}
            title={
                schedule.backupType === 'incremental'
                    ? 'Edit incremental backup schedule'
                    : 'Edit full backup schedule'
            }
            {...rest}
        >
            {schedule.backupType === 'incremental' ? (
                <BackupsScheduleEditIncrementalForm
                    deployment={deployment}
                    form={form}
                    onSuccess={onSuccess}
                    nodesOptions={nodesOptions}
                />
            ) : (
                <BackupsScheduleEditFullForm
                    deployment={deployment}
                    form={form}
                    onSuccess={onSuccess}
                    nodesOptions={nodesOptions}
                />
            )}
        </BackupsFormModal>
    );
}
