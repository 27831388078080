import { useEffect, useState } from 'react';
import InvitesMemberService from '../../services/InvitesService';
import Invites from '../../types/Invites';

export default function useOrganizationInvites(orgId: string) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [invites, setInvites] = useState<Invites>();

    const load = async () => {
        if (!orgId) {
            setLoading(false);
            throw new Error('Organization ID is required');
        }
        try {
            const response = await InvitesMemberService.getOrgInvites(orgId);
            setInvites(response);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { invites, refresh, loading, updating, error };
}
