import { Modal, Form, Input, Button, Row, Col } from 'antd';
import styles from './OrganizationModal.module.less';
import { useEffect, useState } from 'react';
import Organization from '../../../types/Organization';

interface OrganizationModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: { name: string; identifier: string }) => void;
    record: Organization;
    isEditing: boolean;
    testId?: string;
    isLoading?: boolean;
}

const OrganizationModal = ({
    record,
    visible,
    onClose,
    onSubmit,
    isEditing,
    isLoading,
    testId = 'OrganizationModal',
}: OrganizationModalProps) => {
    const [form] = Form.useForm();
    const [isValueUpdated, setIsValueUpdated] = useState(true);
    const [identifier, setIdentifier] = useState<string | undefined>();

    const onFinish = async (values: any) => {
        try {
            await form.validateFields();
            onSubmit(values);
        } catch (error) {
            console.error('Failed to submit:', error);
        }
    };

    useEffect(() => {
        if (record && visible) {
            form.setFieldsValue({
                name: record.name,
                identifier: record.id,
            });
        }
    }, [record, form, visible]);

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onIdentifierChange = (e: any) => {
        formatText(e.target.value);
        setIdentifier(e.target.value);
    };

    const formatText = (inputString: string) => {
        if (!identifier) {
            let identifierValue = inputString.toLowerCase();
            identifierValue = identifierValue.replace(/'/g, '');
            identifierValue = identifierValue.replace(/\s+/g, '-');
            identifierValue = identifierValue.replace(/[^a-z0-9-]/g, '');
            identifierValue = identifierValue.replace(/^-+|-+$/g, '');
            form.setFieldValue('identifier', identifierValue);
            form.validateFields();
        }
    };

    return (
        <>
            <Modal
                title={
                    isEditing
                        ? 'Update Organization'
                        : 'Create New Organization'
                }
                visible={visible}
                onCancel={onCancel}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                data-testid={`${testId}CancelButton`}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid={`${testId}SubmitButton`}
                                onClick={() => onFinish(form.getFieldsValue())}
                                type="primary"
                                loading={isLoading}
                                disabled={isValueUpdated}
                            >
                                {isEditing ? 'Update' : 'Create'}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={() => setIsValueUpdated(false)}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the organization name!',
                            },
                        ]}
                    >
                        <Input
                            className={styles.OrganizationModalInput}
                            placeholder="Organization Name"
                            onChange={(e) => formatText(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Identifier"
                        name="identifier"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please input the unique identifier for the organization!',
                            },
                        ]}
                    >
                        <Input
                            className={styles.OrganizationModalInput}
                            placeholder="Organization Identifier"
                            disabled={isEditing}
                            onBlur={(e) => onIdentifierChange(e)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default OrganizationModal;
