import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import DatabaseVendor from '../../../types/DatabaseVendor';
import styles from './AddServiceCreateDatastoreStep.module.less';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Divider } from 'antd';
import DeploymentOptions from '../../../types/DeploymentOptions';
import CloudProvider from '../../../types/CloudProvider';
import CloudProviderRegion from '../../../types/CloudProviderRegion';
import User from '../../../types/User';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import { WizardFormConfigurationStep1 } from './WizardFormConfigurationStep1';
import { WizardFormConfigurationStep2 } from './WizardFormConfigurationStep2';
import { WizardFormConfigurationStep3 } from './WizardFormConfigurationStep3';
import {
    isOnlyPublicNetworkEnabled,
    WizardFormConfigurationStep4,
} from './WizardFormConfigurationStep4';
import Vpc from '../../../types/Vpc';
import { WizardFormConfigurationStep5 } from './WizardFormConfigurationStep5';
import { WizardFormConfigurationStep6 } from './WizardFormConfigurationStep6';
import Coupon from '../../../types/Coupon';
import Card from '../../../types/Card';
import ProvisionService from '../../../services/ProvisionService';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import { Form } from 'antd';
import {
    billingDisabled,
    eulaEnabled,
    hideCloudProvider,
} from '../../../core/CcxEnv';
import CcxIconInfoCircleTwoTone from '../../ccx/icons/CcxIconInfoCircleTwoTone';
import InstanceVolumeIopsValue from '../../../types/InstanceVolumeIopsValue';
import InstanceVolumeType from '../../../types/InstanceVolumeType';
import PrettyNumber from '../../ccx/common/PrettyNumber';
import { FormInstance } from 'antd/lib/form';
import SetupIntent from '../../../types/SetupIntent';
import { message } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import DataStoreEmailNotificationsSettings from '../../../types/DataStoreEmailNotifications';
import { setInitialState } from '../../../slices/restore.slice';
import InstanceSize from '../../../types/InstanceSize';

interface Props extends CcxComponentProps {
    selectedTech?: DatabaseVendor;
    form: FormInstance;
    databaseVendorType: string;
    handleDeselect: Function;
    handleTagChange: Function;
    handleVendorTypeChange: Function;
    handleCloudProviderChange: Function;
    deploymentOptions?: DeploymentOptions;
    cloudProvider?: CloudProvider;
    availabilityZones: any;
    zones: any;
    handleAvailabilityZoneChange: Function;
    regions?: CloudProviderRegion[];
    handleRegionChange: Function;
    handleInstanceSizeChange: Function;
    user?: User;
    handleVolumeTypeChange: Function;
    handleNetworkChange: Function;
    network: any;
    selectedNetwork: any;
    setNetwork: Function;
    setStep: Function;
    volumeSize?: InstanceVolumeSize;
    volumeType?: InstanceVolumeType;
    volumeIops?: InstanceVolumeIopsValue;
    handleNumberOfNodesChange: Function;
    numberOfNodes: number;
    handleNetworkTypeChange: Function;
    networkType: any;
    handleVpcChange: Function;
    vpcs?: Vpc[];
    vpc?: Vpc;
    logo: any;
    providerLogo: any;
    createUserSubscription: Function;
    handleOnValuesChange: Function;
    enteredVolumeSize?: number;
    setEnteredVolumeSize: Function;
    setEnteredIOPS: Function;
    monthlyCost: number;
    version?: string;
    subscriptionUser: any;
    handleCreateUser: Function;
    handleSetupIntent: Function;
    intentResponse?: SetupIntent;
    setSelectedTechType: Function;
    setSelectedTech: Function;
    setVersion: Function;
    showCloudProviderStep: Function;
    saveEmailNotifications: Function;
    notificationEmails: string[] | undefined;
    region?: CloudProviderRegion;
    selectedCloudProvider?: CloudProvider;
    handleVolumeIopsChange: (
        value: InstanceVolumeIopsValue | undefined
    ) => void;
    instance?: InstanceSize;
    setVolumeSize: React.Dispatch<
        React.SetStateAction<InstanceVolumeSize | undefined>
    >;
}

export default function AddServiceCreateDatastoreStep({
    testId = 'AddServiceCreateDatastoreStep',
    selectedTech,
    form,
    databaseVendorType,
    handleTagChange,
    handleDeselect,
    handleVendorTypeChange,
    deploymentOptions,
    handleCloudProviderChange,
    cloudProvider,
    availabilityZones,
    zones,
    handleAvailabilityZoneChange,
    regions,
    handleRegionChange,
    handleInstanceSizeChange,
    user,
    handleVolumeTypeChange,
    handleNetworkChange,
    setStep,
    volumeSize,
    volumeType,
    volumeIops,
    setVolumeSize,
    network,
    selectedNetwork,
    setNetwork,
    handleNetworkTypeChange,
    networkType,
    handleNumberOfNodesChange,
    numberOfNodes,
    handleVpcChange,
    vpcs,
    vpc,
    logo,
    providerLogo,
    createUserSubscription,
    handleOnValuesChange,
    enteredVolumeSize,
    setEnteredVolumeSize,
    setEnteredIOPS,
    monthlyCost,
    version,
    subscriptionUser,
    handleCreateUser,
    handleSetupIntent,
    intentResponse,
    setSelectedTechType,
    setSelectedTech,
    setVersion,
    showCloudProviderStep,
    saveEmailNotifications,
    notificationEmails,
    selectedCloudProvider,
    region,
    handleVolumeIopsChange,
    instance,
}: Props): ReactElement {
    const [checkedCoupon, setCheckedCoupon] = useState<Coupon>();
    const [selectedCard, setSelectedCard] = useState<Card>();
    const [billingForm] = Form.useForm();
    const [cardToken, setCardToken] = useState<any>();
    const [validNotificationEmails, setValidNotificationEmails] =
        useState<boolean>(false);

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const [eula, setEULA] = useState<boolean>(false);

    const history = useHistory();
    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state) => state.restoreBackup
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (cardToken) {
            handleSetupIntent(cardToken.id);
        }
    }, [cardToken]);

    const handleStepChange = (step: number) => {
        if (step === 0 && !isBackupRestore) {
            setSelectedTech(undefined);
            setVersion(undefined);
            form.setFieldsValue({
                version: undefined,
            });
            setStep('databaseVendor');
        }
    };

    const { subscription } = useAppSelector((state) => state.user);

    useEffect(() => {
        form.setFieldValue('numberOfNodes', numberOfNodes);
    }, []);

    const handleSubmit = async () => {
        try {
            if (eulaEnabled) {
                if (!eula) {
                    message.error('Please agree to EULA to proceed');
                    return;
                }
            }

            setSubmitDisabled(true);
            if (!billingDisabled && !subscription?.valid) {
                if (subscriptionUser && intentResponse) {
                    await createUserSubscription(
                        billingForm,
                        intentResponse.paymentMethodID
                    );
                } else {
                    message.error(
                        'Please complete "Billing address" and "Card information" sections'
                    );
                    return;
                }
            }

            let selectedRegion = form.getFieldValue('region')?.code;

            if (
                hideCloudProvider ||
                deploymentOptions?.hasOnlyOneCloudProviderAndRegion()
            ) {
                selectedRegion = cloudProvider?.getDefaultRegion().code;
            }

            const volumeType =
                form.getFieldValue('volumeType') ??
                form.getFieldValue('storageType') ??
                '';

            const datastoreData: any = {
                clusterName: form.getFieldValue('deploymentName'),
                numberOfNodes: form.getFieldValue('numberOfNodes'),
                databaseVendor: form.getFieldValue('databaseVendor')?.code,
                databaseVendorType: form.getFieldValue('databaseVendorType'),
                tags: form.getFieldValue('tags'),
                cloudProvider: cloudProvider?.code,
                cloudRegion: selectedRegion,
                instanceSize: deploymentOptions?.getInstanceSizeInfo(
                    form.getFieldValue('cloudProvider'),
                    form.getFieldValue('instanceSize')
                )?.code,
                volumeType: deploymentOptions?.getVolumeTypeInfo(
                    form.getFieldValue('cloudProvider'),
                    volumeType
                )?.code,
                volumeSize: form.getFieldValue('volumeSize'),
                volumeIops: form.getFieldValue('volumeIops'),
                networkType: form.getFieldValue('network')?.code,
                haEnabled: form.getFieldValue('networkType') === 'ha',
                vpcUuid: form.getFieldValue('vpcUuid'),
                availabilityZones: form.getFieldValue('availabilityZones'),
                version: form.getFieldValue('version'),
                notifications: new DataStoreEmailNotificationsSettings({
                    enabled: notificationEmails
                        ? notificationEmails.length > 0
                        : false,
                    emails: notificationEmails || [],
                }),
                backupId: restoreDatastore?.backupId,
                dataStoreUuid: restoreDatastore?.dataStoreUuid,
                parameterGroupId: form.getFieldValue('parameterGroup'),
            };

            await ProvisionService.addDeploymentV2(datastoreData);

            form.resetFields();

            setSubmitDisabled(false);

            history.push({
                pathname: '/',
                state: { deploying: true },
            });

            notification.open({
                message: 'Creating datastore',
                description: `Your datastore will get deployed shortly.`,
                icon: <CcxIconInfoCircleTwoTone />,
            });
        } catch (e: any) {
            setSubmitDisabled(false);

            notification.open({
                message: 'Add datastore',
                description: `There was an error creating your datastore. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    const showNetworkStep =
        deploymentOptions &&
        cloudProvider &&
        region &&
        availabilityZones?.length > 1;

    const networkStep = showNetworkStep ? (
        <WizardFormConfiguration.Step
            key="4"
            validate={[
                'network',
                'networkCode',
                'networkType',
                'vpcUuid',
                'availabilityZones',
            ]}
            title="Network settings"
        >
            <WizardFormConfigurationStep4
                deploymentOptions={deploymentOptions}
                cloudProvider={cloudProvider}
                handleNetworkChange={handleNetworkChange}
                network={network}
                setNetwork={setNetwork}
                handleNetworkTypeChange={handleNetworkTypeChange}
                selectedNetwork={selectedNetwork}
                networkType={networkType}
                numberOfNodes={numberOfNodes}
                handleVpcChange={handleVpcChange}
                vpcs={vpcs}
                availabilityZones={availabilityZones}
                zones={zones}
                handleAvailabilityZoneChange={handleAvailabilityZoneChange}
                form={form}
                StepsExtra={() =>
                    !billingDisabled && (
                        <div
                            className={styles.AddServiceCreateDatastorePricing}
                        >
                            <div>Estimated cost:</div>
                            <PrettyNumber
                                prefix={<>&#36;</>}
                                value={monthlyCost}
                                sufix={' / month'}
                            />
                        </div>
                    )
                }
            />
        </WizardFormConfiguration.Step>
    ) : undefined;

    const steps = [
        !isBackupRestore && (
            <WizardFormConfiguration.Step key="0" title="Database">
                <div></div>
            </WizardFormConfiguration.Step>
        ),
        <WizardFormConfiguration.Step
            key="1"
            validate={['deploymentName', 'numberOfNodes', 'databaseVendorType']}
            title="Configuration"
            hasRequiredFields={true}
            nextButtonDisabled={validNotificationEmails}
        >
            <WizardFormConfigurationStep1
                handleTagChange={handleTagChange}
                handleDeselect={handleDeselect}
                handleVendorTypeChange={handleVendorTypeChange}
                databaseVendorType={databaseVendorType}
                deploymentOptions={deploymentOptions}
                selectedTech={selectedTech}
                handleNumberOfNodesChange={handleNumberOfNodesChange}
                numberOfNodes={numberOfNodes}
                form={form}
                saveEmailNotifications={saveEmailNotifications}
                notificationEmails={notificationEmails}
                setValidNotificationEmails={setValidNotificationEmails}
                isBackupRestore={isBackupRestore}
                restoreDatastore={restoreDatastore}
            />
        </WizardFormConfiguration.Step>,
        showCloudProviderStep() && (
            <WizardFormConfiguration.Step
                key="2"
                validate={['cloudProvider', 'region']}
                title="Cloud service"
            >
                <WizardFormConfigurationStep2
                    cloudProviders={deploymentOptions?.getCloudProviders()}
                    regions={regions}
                    selectedCloudProvider={selectedCloudProvider}
                    handleCloudProviderChange={handleCloudProviderChange}
                    handleRegionChange={handleRegionChange}
                    form={form}
                />
            </WizardFormConfiguration.Step>
        ),
        <WizardFormConfiguration.Step
            key="3"
            validate={[
                'instanceSize',
                'volumeType',
                'volumeSize',
                'storageType',
            ]}
            title="Resources"
        >
            {cloudProvider && (
                <WizardFormConfigurationStep3
                    selectedTech={selectedTech}
                    user={user}
                    handleInstanceSizeChange={handleInstanceSizeChange}
                    deploymentOptions={deploymentOptions}
                    cloudProvider={cloudProvider}
                    handleVolumeTypeChange={handleVolumeTypeChange}
                    volumeSize={volumeSize}
                    volumeType={volumeType}
                    volumeIops={volumeIops}
                    setVolumeSize={setVolumeSize}
                    instance={instance}
                    handleVolumeIopsChange={handleVolumeIopsChange}
                    enteredVolumeSize={enteredVolumeSize}
                    setEnteredVolumeSize={setEnteredVolumeSize}
                    setEnteredIOPS={setEnteredIOPS}
                    isBackupRestore={isBackupRestore}
                    form={form}
                    StepsExtra={() =>
                        !billingDisabled && (
                            <div
                                className={
                                    styles.AddServiceCreateDatastorePricing
                                }
                            >
                                <div>Estimated cost:</div>
                                <PrettyNumber
                                    prefix={<>&#36;</>}
                                    value={monthlyCost}
                                    sufix={' / month'}
                                />
                            </div>
                        )
                    }
                />
            )}
        </WizardFormConfiguration.Step>,
        networkStep,
        <WizardFormConfiguration.Step
            key="5"
            title="Preview"
            nextButtonDisabled={submitDisabled}
        >
            <WizardFormConfigurationStep5
                logo={logo}
                providerLogo={providerLogo}
                selectedTech={selectedTech}
                form={form}
                vpc={vpc}
                version={version}
                deploymentOptions={deploymentOptions}
                cloudProvider={cloudProvider}
                setEULA={setEULA}
                eula={eula}
                hideCloudProviderStep={showCloudProviderStep()}
                StepsExtra={() =>
                    !billingDisabled && (
                        <div
                            className={styles.AddServiceCreateDatastorePricing}
                        >
                            <div>Estimated cost:</div>
                            <PrettyNumber
                                prefix={<>&#36;</>}
                                value={monthlyCost}
                                sufix={' / month'}
                            />
                        </div>
                    )
                }
            />
        </WizardFormConfiguration.Step>,
        <WizardFormConfiguration.Step
            key="6"
            title="Billing"
            disabled={true}
            nextButtonDisabled={
                !(subscriptionUser && intentResponse) || submitDisabled
            }
        >
            <WizardFormConfigurationStep6
                setCheckedCoupon={setCheckedCoupon}
                checkedCoupon={checkedCoupon}
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                subscription={subscription}
                billingForm={billingForm}
                setCardToken={setCardToken}
                handleCreateUser={handleCreateUser}
                cardToken={cardToken}
                subscriptionUser={subscriptionUser}
                isCardAttached={!!intentResponse}
            />
        </WizardFormConfiguration.Step>,
    ].filter((step): step is ReactElement => !!step);

    if (billingDisabled || subscription?.valid) {
        steps.pop();
    }

    return (
        <div
            className={styles.AddServiceCreateDatastoreStepContainer}
            data-testid={testId}
        >
            <h4 data-testid={`${testId}title`}>
                Creating a{' '}
                {`${selectedTech?.name} ${
                    version ? version : selectedTech?.version
                }`}{' '}
                datastore
            </h4>
            <Divider className={styles.AddServiceCreateDatastoreDivider} />
            <WizardFormConfiguration
                autoFocusFirstInput={false}
                form={form}
                onStepChange={handleStepChange}
                onCancel={() => {
                    dispatch(setInitialState());
                    history.goBack();
                }}
                onSubmit={handleSubmit}
                onValuesChange={() => handleOnValuesChange()}
                defaultActiveStep="1"
                steps={steps}
                stepsExtra={(stepKey: string, stepIndex: number) =>
                    ((!billingDisabled && stepIndex >= 3) ||
                        (!billingDisabled &&
                            isBackupRestore &&
                            stepIndex >= 2)) && (
                        <div
                            className={styles.AddServiceCreateDatastorePricing}
                        >
                            <div>Estimated cost:</div>
                            <PrettyNumber
                                prefix={<>&#36;</>}
                                value={monthlyCost}
                                sufix={' / month'}
                            />
                        </div>
                    )
                }
            />
        </div>
    );
}
