import { Alert, Form, Radio, Select } from 'antd';
import { useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import DeploymentsItem from '../../types/DeploymentsItem';
import useBackupSettings from '../../core/hooks/useBackupSettings';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import AppLoading from '../AppLoading';
import Space from '../common/antd/Space';
import BackupScheduleInfo from './FormParts/BackupScheduleInfo';
import styles from './BackupsScheduleEditIncrementalForm.module.less';
import { Draft } from '@reduxjs/toolkit';
import { SelectFieldOptions } from '../../core/helpers';

const SCHEDULE_OPTIONS = [
    { label: '15 min', value: '*/15 * * * *' },
    { label: '30 min', value: '*/30 * * * *' },
    { label: '1 hour', value: '0 * * * *' },
];

type BackupsScheduleEditIncrementalFormProps = {
    deployment: Draft<DeploymentsItem>;
    form: FormInstance;
    onSuccess?: () => void;
    nodesOptions?: SelectFieldOptions[];
};

export default function BackupsScheduleEditIncrementalForm({
    deployment,
    form,
    onSuccess,
    nodesOptions,
}: BackupsScheduleEditIncrementalFormProps) {
    const { backupSettings, update, loading }: any = useBackupSettings(
        deployment.dataStoreUuid
    );

    const inOptions = (value: string) => {
        return !!SCHEDULE_OPTIONS.find((option) => option.value === value);
    };
    const initialValues = useMemo(() => {
        if (loading) return undefined;
        if (!backupSettings) return undefined;
        let cron = backupSettings.incCron;
        if (!inOptions(cron)) {
            cron = SCHEDULE_OPTIONS[0].value;
        }
        return {
            cron,
            node_id: backupSettings.incNodeId,
        };
    }, [backupSettings, loading]);

    const handleSubmit = async ({ cron, node_id }: any) => {
        try {
            await update({
                incremental: {
                    frequency: backupSettings.incFrequency,
                    cron,
                    node_id,
                },
            });
            onSuccess?.();
        } catch (e: any) {
            console.error(e);
        }
    };

    const invalidOption =
        backupSettings?.incCron && !inOptions(backupSettings?.incCron)
            ? backupSettings?.incCron
            : undefined;

    return loading ? (
        <AppLoading />
    ) : (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
        >
            <Space wide={true} direction={'vertical'} size={10}>
                {invalidOption && (
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => (
                            <Alert
                                message={
                                    <span>
                                        Current interval is{' '}
                                        <b>
                                            <CronFormat phrase={true}>
                                                {invalidOption}
                                            </CronFormat>{' '}
                                            ({invalidOption})
                                        </b>{' '}
                                        will be overridden by selected option (
                                        {form.getFieldValue('cron')})
                                    </span>
                                }
                                type={'warning'}
                            />
                        )}
                    </Form.Item>
                )}

                <Form.Item
                    name="cron"
                    label="Start the incremental backup every"
                    rules={[
                        {
                            required: true,
                            message: 'Please select an interval',
                        },
                    ]}
                >
                    <Radio.Group
                        options={SCHEDULE_OPTIONS}
                        optionType="button"
                        className={styles.IncrementalRadioOptions}
                    />
                </Form.Item>

                {(deployment?.isPostgreSql() ||
                    deployment?.isMariaDb() ||
                    deployment?.isPercona()) && (
                    <Form.Item name="node_id" label="Backup Node">
                        <Select
                            placeholder={'Select node for backups'}
                            className={styles.AppFormSelect}
                        >
                            <Select.Option value={'auto'} key={'auto'}>
                                Auto
                            </Select.Option>
                            {nodesOptions?.map((n: any) => (
                                <Select.Option value={n.value} key={n.value}>
                                    {n.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                <BackupScheduleInfo
                    form={form}
                    message={'Your incremental backup will start'}
                />
            </Space>
        </Form>
    );
}
