import { useRBAC } from '../hooks/useRBAC';
import { Permission } from '../PermissionRoles';
import { Tooltip } from 'antd';
import { DeniedOutlined } from '@severalnines/frontend_hub/libs/icons';
import { RBAC_ENABLED } from '../CcxEnv';

interface CanProps {
    permission: Permission;
    children: React.ReactNode;
    showTooltip: boolean;
}

const Can = ({ permission, children, showTooltip }: CanProps) => {
    const { hasPermission } = useRBAC();

    if (!RBAC_ENABLED) {
        return <>{children}</>;
    }

    return hasPermission(permission) ? (
        <>{children}</>
    ) : showTooltip ? (
        <Tooltip title="You do not have permission to perform this action.">
            <DeniedOutlined />
        </Tooltip>
    ) : (
        <></>
    );
};

export default Can;
