import AppLoading from '../AppLoading';
import AppTable from '../AppTable';
import AppEmpty from '../AppEmpty';
import useBackupSchedules from '../../core/hooks/useBackupSchedules';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import ExpandableText from '../ExpandableText';
import DeploymentsItem from '../../types/DeploymentsItem';
import BackupsScheduleActionsMenu from './BackupsScheduleActionsMenu';
import BackupScheduleStatusFormat from './BackupScheduleStatusFormat';
import { Tooltip } from 'antd';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import BackupSchedule from '../../types/BackupSchedule';
import { Draft } from '@reduxjs/toolkit';
import { CAN_MODIFY_BACKUP_SCHEDULE } from '../../core/PermissionRoles';
import Can from '../../core/providers/Can';

type BackupsScheduleListProps = {
    deployment: Draft<DeploymentsItem>;
};
export default function BackupsScheduleList({
    deployment,
}: BackupsScheduleListProps) {
    const { backupSchedules, loading, refresh } = useBackupSchedules(
        deployment.dataStoreUuid
    );

    const handleActionPerformed = () => {
        refresh();
    };

    const columns = [
        {
            title: 'Method',
            dataIndex: 'backupMethod',
            key: 'backupMethod',
        },
        {
            title: 'Type',
            dataIndex: 'backupType',
            key: 'backupType',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: BackupSchedule) => (
                <BackupScheduleStatusFormat status={record.status} />
            ),
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 230,
            render: (text: string, record: BackupSchedule) => {
                const a = new FriendlyDateTime({
                    date: record.createdAt || '',
                });
                return (
                    <ExpandableText
                        collapsedText={a?.getFullDate()}
                        expandedText={a?.getFriendlyDate()}
                    />
                );
            },
        },
        {
            title: 'Recurrence',
            dataIndex: 'recurrence',
            key: 'recurrence',
            render: (text: string, record: BackupSchedule) => {
                return (
                    <Tooltip
                        title={
                            <CronFormat>{record.getCronString()}</CronFormat>
                        }
                    >
                        {record.recurrence}
                    </Tooltip>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: string, record: BackupSchedule) => (
                <Can permission={CAN_MODIFY_BACKUP_SCHEDULE} showTooltip={true}>
                    <BackupsScheduleActionsMenu
                        disabled={!deployment?.isOperable()}
                        deployment={deployment}
                        schedule={record}
                        onActionPerformed={handleActionPerformed}
                    />
                </Can>
            ),
        },
    ];
    return loading ? (
        <AppLoading />
    ) : backupSchedules?.length > 0 ? (
        <AppTable
            columns={columns}
            data={backupSchedules}
            rowKey="created"
            pagination={{
                hideOnSinglePage: true,
            }}
        />
    ) : (
        <AppEmpty message="There are no Backup Schedules created." />
    );
}
