/* tslint:disable */
/* eslint-disable */
/**
 * CCX Backup Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * Contact: support@severalnines.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Backup
 */
export interface Backup {
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    'backup_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    'chain_up'?: number;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    'children'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Backup
     */
    'has_children'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    'backup_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    'backup_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    'status'?: string;
    /**
     * Backup size in bytes
     * @type {number}
     * @memberof Backup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    'ended_at'?: string | null;
}
/**
 * 
 * @export
 * @interface BackupsDatastoreUuidBackupIdRestorePostRequest
 */
export interface BackupsDatastoreUuidBackupIdRestorePostRequest {
    /**
     * PITR stop time in RFC3339 format
     * @type {string}
     * @memberof BackupsDatastoreUuidBackupIdRestorePostRequest
     */
    'pitr_stop_time'?: string;
}
/**
 * 
 * @export
 * @interface BackupsDatastoreUuidGet200Response
 */
export interface BackupsDatastoreUuidGet200Response {
    /**
     * 
     * @type {Array<Backup>}
     * @memberof BackupsDatastoreUuidGet200Response
     */
    'backups': Array<Backup>;
    /**
     * 
     * @type {number}
     * @memberof BackupsDatastoreUuidGet200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface BackupsDatastoreUuidGet500Response
 */
export interface BackupsDatastoreUuidGet500Response {
    /**
     * 
     * @type {number}
     * @memberof BackupsDatastoreUuidGet500Response
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof BackupsDatastoreUuidGet500Response
     */
    'err': string;
}
/**
 * 
 * @export
 * @interface Pbtime
 */
export interface Pbtime {
    /**
     * 
     * @type {number}
     * @memberof Pbtime
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pbtime
     */
    'nanos'?: number;
}
/**
 * 
 * @export
 * @interface Restore
 */
export interface Restore {
    /**
     * 
     * @type {string}
     * @memberof Restore
     */
    'user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Restore
     */
    'restore_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Restore
     */
    'datastore_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Restore
     */
    'controller_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof Restore
     */
    'cmon_cluster_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Restore
     */
    'cmon_job_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Restore
     */
    'cmon_backup_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Restore
     */
    'progress'?: number;
    /**
     * DEFINED = 0; DEQUEUED = 1; RUNNING = 2; RUNNING2 = 3; RUNNING3 = 4; RUNNING_EXT = 5; ABORTED = 6; FINISHED = 7; FAILED = 8; 
     * @type {number}
     * @memberof Restore
     */
    'status'?: RestoreStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Restore
     */
    'status_text'?: string;
    /**
     * 
     * @type {Pbtime}
     * @memberof Restore
     */
    'created_at'?: Pbtime;
    /**
     * 
     * @type {Pbtime}
     * @memberof Restore
     */
    'updated_at'?: Pbtime;
    /**
     * 
     * @type {Pbtime}
     * @memberof Restore
     */
    'started_at'?: Pbtime;
    /**
     * 
     * @type {Pbtime}
     * @memberof Restore
     */
    'ended_at'?: Pbtime;
    /**
     * 
     * @type {Backup}
     * @memberof Restore
     */
    'backup'?: Backup;
}

export const RestoreStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type RestoreStatusEnum = typeof RestoreStatusEnum[keyof typeof RestoreStatusEnum];

/**
 * 
 * @export
 * @interface RestoresDatastoreUuidGet200Response
 */
export interface RestoresDatastoreUuidGet200Response {
    /**
     * 
     * @type {Array<Restore>}
     * @memberof RestoresDatastoreUuidGet200Response
     */
    'restores'?: Array<Restore>;
    /**
     * 
     * @type {number}
     * @memberof RestoresDatastoreUuidGet200Response
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RetentionSettings
 */
export interface RetentionSettings {
    /**
     * Positive number to indicate number of days for backup retention
     * @type {number}
     * @memberof RetentionSettings
     */
    'retention_days'?: number;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'backup_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'backup_type'?: ScheduleBackupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'status'?: string;
    /**
     * Schedule in crontab format
     * @type {string}
     * @memberof Schedule
     */
    'recurrence'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'created_at'?: string;
}

export const ScheduleBackupTypeEnum = {
    Full: 'full',
    Incremental: 'incremental'
} as const;

export type ScheduleBackupTypeEnum = typeof ScheduleBackupTypeEnum[keyof typeof ScheduleBackupTypeEnum];

/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * How often to run a full backup. 0 - never; 1 - hourly; 2 - daily.
     * @type {number}
     * @memberof Settings
     */
    'full_frequency'?: SettingsFullFrequencyEnum;
    /**
     * When to start a full backup if it is set to \"2 - daily\".
     * @type {number}
     * @memberof Settings
     */
    'full_start_hour'?: number;
    /**
     * How often to run incremental backups. 0 - never; 1 - every5 minutes.
     * @type {number}
     * @memberof Settings
     */
    'inc_frequency'?: SettingsIncFrequencyEnum;
    /**
     * Valid crontab string to schedule full_cron
     * @type {string}
     * @memberof Settings
     */
    'full_cron'?: string;
    /**
     * Valid crontab string to schedule incremental_cron
     * @type {string}
     * @memberof Settings
     */
    'inc_cron'?: string;
}

export const SettingsFullFrequencyEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type SettingsFullFrequencyEnum = typeof SettingsFullFrequencyEnum[keyof typeof SettingsFullFrequencyEnum];
export const SettingsIncFrequencyEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type SettingsIncFrequencyEnum = typeof SettingsIncFrequencyEnum[keyof typeof SettingsIncFrequencyEnum];

/**
 * 
 * @export
 * @interface SettingsSet
 */
export interface SettingsSet {
    /**
     * 
     * @type {SettingsSetFull}
     * @memberof SettingsSet
     */
    'full'?: SettingsSetFull;
    /**
     * 
     * @type {SettingsSetIncremental}
     * @memberof SettingsSet
     */
    'incremental'?: SettingsSetIncremental;
    /**
     * 
     * @type {string}
     * @memberof SettingsSet
     */
    'node_id'?: string;
}
/**
 * 
 * @export
 * @interface SettingsSetFull
 */
export interface SettingsSetFull {
    /**
     * How often to run a full backup. 0 - never; 1 - hourly; 2 - daily.
     * @type {number}
     * @memberof SettingsSetFull
     */
    'frequency'?: SettingsSetFullFrequencyEnum;
    /**
     * When to start a full backup if it is set to \"2 - daily\".
     * @type {number}
     * @memberof SettingsSetFull
     */
    'start_hour'?: number;
    /**
     * Valid crontab string to schedule full backup
     * @type {string}
     * @memberof SettingsSetFull
     */
    'cron'?: string;
}

export const SettingsSetFullFrequencyEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type SettingsSetFullFrequencyEnum = typeof SettingsSetFullFrequencyEnum[keyof typeof SettingsSetFullFrequencyEnum];

/**
 * 
 * @export
 * @interface SettingsSetIncremental
 */
export interface SettingsSetIncremental {
    /**
     * How often to run incremental backups. 0 - never; 1 - every 5 minutes.
     * @type {number}
     * @memberof SettingsSetIncremental
     */
    'frequency'?: SettingsSetIncrementalFrequencyEnum;
    /**
     * Valid crontab string to schedule incremental backup
     * @type {string}
     * @memberof SettingsSetIncremental
     */
    'cron'?: string;
}

export const SettingsSetIncrementalFrequencyEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type SettingsSetIncrementalFrequencyEnum = typeof SettingsSetIncrementalFrequencyEnum[keyof typeof SettingsSetIncrementalFrequencyEnum];


/**
 * BackupApi - axios parameter creator
 * @export
 */
export const BackupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Backup schedules list
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupSchedulesDatastoreUuidGet: async (datastoreUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('backupSchedulesDatastoreUuidGet', 'datastoreUuid', datastoreUuid)
            const localVarPath = `/backup_schedules/{datastore_uuid}`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Backups list
         * @param {string} datastoreUuid 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupsDatastoreUuidGet: async (datastoreUuid: string, parentId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('backupsDatastoreUuidGet', 'datastoreUuid', datastoreUuid)
            const localVarPath = `/backups/{datastore_uuid}`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackupApi - functional programming interface
 * @export
 */
export const BackupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Backup schedules list
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backupSchedulesDatastoreUuidGet(datastoreUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Schedule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backupSchedulesDatastoreUuidGet(datastoreUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Backups list
         * @param {string} datastoreUuid 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backupsDatastoreUuidGet(datastoreUuid: string, parentId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackupsDatastoreUuidGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backupsDatastoreUuidGet(datastoreUuid, parentId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackupApi - factory interface
 * @export
 */
export const BackupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackupApiFp(configuration)
    return {
        /**
         * 
         * @summary Backup schedules list
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupSchedulesDatastoreUuidGet(datastoreUuid: string, options?: any): AxiosPromise<Array<Schedule>> {
            return localVarFp.backupSchedulesDatastoreUuidGet(datastoreUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Backups list
         * @param {string} datastoreUuid 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupsDatastoreUuidGet(datastoreUuid: string, parentId?: number, page?: number, perPage?: number, options?: any): AxiosPromise<BackupsDatastoreUuidGet200Response> {
            return localVarFp.backupsDatastoreUuidGet(datastoreUuid, parentId, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackupApi - object-oriented interface
 * @export
 * @class BackupApi
 * @extends {BaseAPI}
 */
export class BackupApi extends BaseAPI {
    /**
     * 
     * @summary Backup schedules list
     * @param {string} datastoreUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public backupSchedulesDatastoreUuidGet(datastoreUuid: string, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).backupSchedulesDatastoreUuidGet(datastoreUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Backups list
     * @param {string} datastoreUuid 
     * @param {number} [parentId] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public backupsDatastoreUuidGet(datastoreUuid: string, parentId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).backupsDatastoreUuidGet(datastoreUuid, parentId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RestoreApi - axios parameter creator
 * @export
 */
export const RestoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Restore a backup
         * @param {string} datastoreUuid 
         * @param {number} backupId 
         * @param {BackupsDatastoreUuidBackupIdRestorePostRequest} [backupsDatastoreUuidBackupIdRestorePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupsDatastoreUuidBackupIdRestorePost: async (datastoreUuid: string, backupId: number, backupsDatastoreUuidBackupIdRestorePostRequest?: BackupsDatastoreUuidBackupIdRestorePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('backupsDatastoreUuidBackupIdRestorePost', 'datastoreUuid', datastoreUuid)
            // verify required parameter 'backupId' is not null or undefined
            assertParamExists('backupsDatastoreUuidBackupIdRestorePost', 'backupId', backupId)
            const localVarPath = `/backups/{datastore_uuid}/{backup_id}/restore`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)))
                .replace(`{${"backup_id"}}`, encodeURIComponent(String(backupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backupsDatastoreUuidBackupIdRestorePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List backup restore jobs
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoresDatastoreUuidGet: async (datastoreUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('restoresDatastoreUuidGet', 'datastoreUuid', datastoreUuid)
            const localVarPath = `/restores/{datastore_uuid}`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestoreApi - functional programming interface
 * @export
 */
export const RestoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Restore a backup
         * @param {string} datastoreUuid 
         * @param {number} backupId 
         * @param {BackupsDatastoreUuidBackupIdRestorePostRequest} [backupsDatastoreUuidBackupIdRestorePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backupsDatastoreUuidBackupIdRestorePost(datastoreUuid: string, backupId: number, backupsDatastoreUuidBackupIdRestorePostRequest?: BackupsDatastoreUuidBackupIdRestorePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Restore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backupsDatastoreUuidBackupIdRestorePost(datastoreUuid, backupId, backupsDatastoreUuidBackupIdRestorePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List backup restore jobs
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoresDatastoreUuidGet(datastoreUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestoresDatastoreUuidGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoresDatastoreUuidGet(datastoreUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RestoreApi - factory interface
 * @export
 */
export const RestoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Restore a backup
         * @param {string} datastoreUuid 
         * @param {number} backupId 
         * @param {BackupsDatastoreUuidBackupIdRestorePostRequest} [backupsDatastoreUuidBackupIdRestorePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupsDatastoreUuidBackupIdRestorePost(datastoreUuid: string, backupId: number, backupsDatastoreUuidBackupIdRestorePostRequest?: BackupsDatastoreUuidBackupIdRestorePostRequest, options?: any): AxiosPromise<Restore> {
            return localVarFp.backupsDatastoreUuidBackupIdRestorePost(datastoreUuid, backupId, backupsDatastoreUuidBackupIdRestorePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List backup restore jobs
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoresDatastoreUuidGet(datastoreUuid: string, options?: any): AxiosPromise<RestoresDatastoreUuidGet200Response> {
            return localVarFp.restoresDatastoreUuidGet(datastoreUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RestoreApi - object-oriented interface
 * @export
 * @class RestoreApi
 * @extends {BaseAPI}
 */
export class RestoreApi extends BaseAPI {
    /**
     * 
     * @summary Restore a backup
     * @param {string} datastoreUuid 
     * @param {number} backupId 
     * @param {BackupsDatastoreUuidBackupIdRestorePostRequest} [backupsDatastoreUuidBackupIdRestorePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestoreApi
     */
    public backupsDatastoreUuidBackupIdRestorePost(datastoreUuid: string, backupId: number, backupsDatastoreUuidBackupIdRestorePostRequest?: BackupsDatastoreUuidBackupIdRestorePostRequest, options?: AxiosRequestConfig) {
        return RestoreApiFp(this.configuration).backupsDatastoreUuidBackupIdRestorePost(datastoreUuid, backupId, backupsDatastoreUuidBackupIdRestorePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List backup restore jobs
     * @param {string} datastoreUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestoreApi
     */
    public restoresDatastoreUuidGet(datastoreUuid: string, options?: AxiosRequestConfig) {
        return RestoreApiFp(this.configuration).restoresDatastoreUuidGet(datastoreUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Backup settings of a datastore
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidGet: async (datastoreUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('settingsDatastoreUuidGet', 'datastoreUuid', datastoreUuid)
            const localVarPath = `/settings/{datastore_uuid}`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update backup settings of a datastore. At least one of \'full\' or \'incremental\' must be set.
         * @param {string} datastoreUuid 
         * @param {SettingsSet} settingsSet Backup settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidPatch: async (datastoreUuid: string, settingsSet: SettingsSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('settingsDatastoreUuidPatch', 'datastoreUuid', datastoreUuid)
            // verify required parameter 'settingsSet' is not null or undefined
            assertParamExists('settingsDatastoreUuidPatch', 'settingsSet', settingsSet)
            const localVarPath = `/settings/{datastore_uuid}`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Backup settings of a datastore
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidRetentionGet: async (datastoreUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('settingsDatastoreUuidRetentionGet', 'datastoreUuid', datastoreUuid)
            const localVarPath = `/settings/{datastore_uuid}/retention`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update backup retention settings of a datastore
         * @param {string} datastoreUuid 
         * @param {RetentionSettings} retentionSettings Backup retention settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidRetentionPost: async (datastoreUuid: string, retentionSettings: RetentionSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datastoreUuid' is not null or undefined
            assertParamExists('settingsDatastoreUuidRetentionPost', 'datastoreUuid', datastoreUuid)
            // verify required parameter 'retentionSettings' is not null or undefined
            assertParamExists('settingsDatastoreUuidRetentionPost', 'retentionSettings', retentionSettings)
            const localVarPath = `/settings/{datastore_uuid}/retention`
                .replace(`{${"datastore_uuid"}}`, encodeURIComponent(String(datastoreUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retentionSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Backup settings of a datastore
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsDatastoreUuidGet(datastoreUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsDatastoreUuidGet(datastoreUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update backup settings of a datastore. At least one of \'full\' or \'incremental\' must be set.
         * @param {string} datastoreUuid 
         * @param {SettingsSet} settingsSet Backup settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsDatastoreUuidPatch(datastoreUuid: string, settingsSet: SettingsSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsDatastoreUuidPatch(datastoreUuid, settingsSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Backup settings of a datastore
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsDatastoreUuidRetentionGet(datastoreUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetentionSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsDatastoreUuidRetentionGet(datastoreUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update backup retention settings of a datastore
         * @param {string} datastoreUuid 
         * @param {RetentionSettings} retentionSettings Backup retention settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsDatastoreUuidRetentionPost(datastoreUuid: string, retentionSettings: RetentionSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetentionSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsDatastoreUuidRetentionPost(datastoreUuid, retentionSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Backup settings of a datastore
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidGet(datastoreUuid: string, options?: any): AxiosPromise<Settings> {
            return localVarFp.settingsDatastoreUuidGet(datastoreUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update backup settings of a datastore. At least one of \'full\' or \'incremental\' must be set.
         * @param {string} datastoreUuid 
         * @param {SettingsSet} settingsSet Backup settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidPatch(datastoreUuid: string, settingsSet: SettingsSet, options?: any): AxiosPromise<Settings> {
            return localVarFp.settingsDatastoreUuidPatch(datastoreUuid, settingsSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Backup settings of a datastore
         * @param {string} datastoreUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidRetentionGet(datastoreUuid: string, options?: any): AxiosPromise<RetentionSettings> {
            return localVarFp.settingsDatastoreUuidRetentionGet(datastoreUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update backup retention settings of a datastore
         * @param {string} datastoreUuid 
         * @param {RetentionSettings} retentionSettings Backup retention settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsDatastoreUuidRetentionPost(datastoreUuid: string, retentionSettings: RetentionSettings, options?: any): AxiosPromise<RetentionSettings> {
            return localVarFp.settingsDatastoreUuidRetentionPost(datastoreUuid, retentionSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Backup settings of a datastore
     * @param {string} datastoreUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingsDatastoreUuidGet(datastoreUuid: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingsDatastoreUuidGet(datastoreUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update backup settings of a datastore. At least one of \'full\' or \'incremental\' must be set.
     * @param {string} datastoreUuid 
     * @param {SettingsSet} settingsSet Backup settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingsDatastoreUuidPatch(datastoreUuid: string, settingsSet: SettingsSet, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingsDatastoreUuidPatch(datastoreUuid, settingsSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Backup settings of a datastore
     * @param {string} datastoreUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingsDatastoreUuidRetentionGet(datastoreUuid: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingsDatastoreUuidRetentionGet(datastoreUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update backup retention settings of a datastore
     * @param {string} datastoreUuid 
     * @param {RetentionSettings} retentionSettings Backup retention settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingsDatastoreUuidRetentionPost(datastoreUuid: string, retentionSettings: RetentionSettings, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingsDatastoreUuidRetentionPost(datastoreUuid, retentionSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


