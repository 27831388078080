import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UsersService from '../services/UsersService';
import User from '../types/User';
import ProfileService from '../services/ProfileService';
import Subscription from '../types/Subscription';
import BillingService from '../services/BillingService';
import BillingUserData from '../types/BillingUserData';
import BillingAddress from '../types/BillingAddress';
import { getLocalStorage, setLocalStorage } from '../core/helpers';

interface UserType {
    user: User | undefined;
    billingUserData: BillingUserData | undefined;
    billingAddress: BillingAddress | undefined;
    subscription: Subscription | undefined;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
const initialState = {
    user: undefined,
    billingUserData: undefined,
    billingAddress: undefined,
    subscription: undefined,
    isError: false,
    isSuccess: false,
    isLoading: false,
} as UserType;

export const userCheck = createAsyncThunk('user/check', async () => {
    const userScope = await UsersService.check();
    setLocalStorage('org', userScope.getDefaultScope());
    return userScope;
});

export const updateProfile = createAsyncThunk('profile/update', async () => {
    const response = await ProfileService.updateProfile('false');
    return response;
});

export const checkUserSubscription = createAsyncThunk(
    'user/checkSubscription',
    async () => {
        const response = await BillingService.checkUserSubscription();
        return response;
    },
    {
        condition: (_, { getState }: any) => {
            const { user } = getState();
            if (user.subscription) {
                return false;
            }
            return true;
        },
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userCheck.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userCheck.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(userCheck.rejected, (state, _action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(updateProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(updateProfile.rejected, (state, _action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(checkUserSubscription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(checkUserSubscription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subscription = action.payload;
                state.billingAddress =
                    action?.payload?.billingUser?.addressData;
                state.billingUserData = action?.payload?.billingUser?.userData;
            });
    },
});

export default userSlice.reducer;
