// useRBAC.ts
import { getLocalStorage } from '../helpers';
import { PermissionRoles, Permission } from '../PermissionRoles';

export const useRBAC = () => {
    const activeOrg = getLocalStorage('org');

    const hasPermission = (permission: Permission): boolean => {
        const rolePermissions =
            PermissionRoles[
                activeOrg?.role?.toLowerCase() as keyof typeof PermissionRoles
            ] || {};
        return !!rolePermissions[permission];
    };

    return { hasPermission };
};
