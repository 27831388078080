import { ReactElement, useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { SettingOutlined } from '@severalnines/frontend_hub/libs/icons';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import ButtonWithOverlayDialog from '../common/ButtonWithOverlayDialog';
import BackupsList from './BackupsList';
import BackupsScheduleList from './BackupsScheduleList';
import { useHistory } from 'react-router';
import BackupSettingsModal from './BackupsSettingsModal';
import { useAppSelector } from '../../redux/hooks';
import styles from './Backups.module.less';
import {
    CAN_MODIFY_BACKUP_SETTINGS,
    CAN_RESTORE_DATASTORE,
} from '../../core/PermissionRoles';
import { useRBAC } from '../../core/hooks/useRBAC';
import Can from '../../core/providers/Can';

export type UrlProps = {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
};

interface BackupsProps {
    testId?: string;
}

function Backups({ testId = 'Backup' }: BackupsProps): ReactElement {
    const history = useHistory();
    const { hasPermission } = useRBAC();
    const { dataStoreUuid, projectUuid, tab } = useParams<UrlProps>();
    const { dataStore: deployment } = useAppSelector(
        (state) => state.dataStore
    );
    const [isBackupInProcess, setIsBackupInProcess] = useState<boolean>(
        !deployment?.isOperable()
    );

    useEffect(() => {
        if (!hasPermission(CAN_RESTORE_DATASTORE)) {
            history.push(
                `/projects/${projectUuid}/data-stores/${dataStoreUuid}/backups/schedules`
            );
        }
    }, []);

    return (
        <Tabs
            data-testid={`${testId}Settings`}
            tabBarExtraContent={
                deployment && (
                    <Can
                        permission={CAN_MODIFY_BACKUP_SETTINGS}
                        showTooltip={false}
                    >
                        <ButtonWithOverlayDialog
                            button={
                                <Button
                                    className={styles.BackupSettingsButton}
                                    data-testid={`${testId}SettingsButton`}
                                    icon={<SettingOutlined />}
                                    disabled={isBackupInProcess}
                                >
                                    <span className={styles.IconTextMargin4}>
                                        Backup settings
                                    </span>
                                </Button>
                            }
                            overlay={
                                <BackupSettingsModal deployment={deployment} />
                            }
                        />
                    </Can>
                )
            }
            activeKey={tab || 'backups'}
            onChange={(tabKey: any) => {
                const tab = tabKey === 'backups' ? '' : `/${tabKey}`;
                history.push(
                    `/projects/${projectUuid}/data-stores/${dataStoreUuid}/backups${tab}`
                );
            }}
        >
            <Can permission={CAN_RESTORE_DATASTORE} showTooltip={false}>
                <Tabs.TabPane
                    tab="All backups"
                    key="backups"
                    style={{ paddingTop: 20 }}
                >
                    {(deployment && (
                        <BackupsList
                            deployment={deployment}
                            setIsBackupInProcess={setIsBackupInProcess}
                        />
                    )) || <AppEmpty message="No datastore was retrieved" />}
                </Tabs.TabPane>
            </Can>

            <Tabs.TabPane
                tab="Backup schedules"
                key="schedules"
                style={{ paddingTop: 20 }}
            >
                {(deployment && (
                    <BackupsScheduleList deployment={deployment} />
                )) || <AppEmpty message="No datastore was retrieved" />}
            </Tabs.TabPane>
        </Tabs>
    );
}

export default Backups;
