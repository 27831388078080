import moment from 'moment';
import Invite, { InviteClassProps } from './Invite';

export type InvitesClassProps = {
    invites: Invite[];
    total: number;
};

export interface InvitesInterface {
    invites: Invite[];
    total: number;
}

export default class Invites implements InvitesInterface {
    readonly invites: Invite[];
    readonly total: number;

    constructor(props: InvitesClassProps) {
        this.invites = props.invites.map((invite: any) => {
            return new Invite(invite);
        });
        this.total = props.total;
    }

    getAllPendingInvites = () => {
        return this.invites.filter((invite: Invite) => {
            if (
                !invite.acceptedAt &&
                !invite.declinedAt &&
                moment(invite.expiresAt) > moment()
            ) {
                return invite;
            }
        });
    };

    getFirstPendingInvites = () => {
        return this.invites.filter((invite: Invite) => {
            if (
                !invite.acceptedAt &&
                !invite.declinedAt &&
                moment(invite.expiresAt) > moment()
            ) {
                return invite;
            }
        })[0];
    };

    // getAllPendingInvites = () => {
    //     return this.invites.filter((invite: Invite) => {
    //         if (invite.declinedAt || moment(invite.expiresAt) > moment()) {
    //             return invite;
    //         }
    //     });
    // };
}
