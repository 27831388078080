import Member, { MemberClassProps } from './Member';

interface MembersClassProps {
    total: number;
    members: MemberClassProps[];
}

export default class Members implements MembersClassProps {
    readonly total: number;
    readonly members: Member[];

    constructor(props: MembersClassProps) {
        this.total = props.total;
        this.members = props.members.map((member) => new Member(member));
    }

    getAdminEmail = () => {
        return (
            this.members.find((member) => member.role === 'Admin')?.name || ''
        );
    };
}
