import { useMemo, useState } from 'react';
import { Dropdown, Button, Menu, notification } from 'antd';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import {
    EditOutlined,
    LogoutOutlined,
    UserDeleteOutlined,
} from '@ant-design/icons';
import styles from './OrganizationsActionsList.module.less';
import MembersService from '../../../services/MembersService';
import MemberFormModal from './MemberFormModal';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { isActiveUser } from '../../../core/helpers';
import InviteMemberService from '../../../services/InvitesService';
import MemberDeletePopover from './MemberDeletePopover';
import { useHistory } from 'react-router';

interface MembersActionsListProps {
    record: MemberRecord;
    refresh: () => void;
    refreshInvites: () => void;
    isAdmin: boolean;
    orgId: string;
}

export interface MemberRecord {
    id: string;
    role: string;
    status: string;
    email?: string;
    userId?: string;
    user?: string;
}

const MembersActionsList = ({
    record,
    refresh,
    refreshInvites,
    isAdmin,
    orgId,
}: MembersActionsListProps) => {
    const { user } = useAppSelector((state: RootState) => state.user);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const handleEdit = () => {
        if (!isEditVisible) setIsEditVisible(true);
    };

    const handleDelete = () => {
        if (!isDeleteVisible) setIsDeleteVisible(true);
    };

    const handleUpdateMember = async (values: {
        email: string;
        role: string;
        userId: string;
    }) => {
        setIsLoading(true);
        try {
            await MembersService.updateMember(
                orgId,
                values.userId,
                values.role
            );
            notification.success({
                message: 'Member Updated',
                description: 'The member has been updated successfully.',
            });
            refresh();
        } catch (e: any) {
            notification.error({
                message: 'Member Update Failed',
                description: 'There was an error updating the member.',
            });
        } finally {
            setIsLoading(false);
            setIsEditVisible(false);
        }
    };

    const handleDeleteMember = async () => {
        if (isAdmin && record.status === 'Active') {
            handleRemoveMember();
        } else if (record.status !== 'Active') {
            handleDeleteInvite();
        } else {
            handleLeaveOrg();
        }
        return;
    };

    const handleClose = () => {
        setIsEditVisible(false);
    };

    const handleRemoveMember = async () => {
        setIsLoading(true);
        try {
            await MembersService.deleteMember(orgId, record.id);
            notification.success({
                message: 'Member Removed',
                description: 'The member has been removed successfully.',
            });
            refresh();
        } catch (e: any) {
            notification.error({
                message: 'Member Removal Failed',
                description: 'There was an error removing the member.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleLeaveOrg = async () => {
        try {
            await MembersService.leaveOrganization(orgId);
            notification.success({
                message: 'Left Organization',
                description: 'You have left the organization successfully.',
            });
            history.push(`/organizations`);
        } catch (e: any) {
            notification.error({
                message: 'Failed to Leave Organization',
                description: 'There was an error leaving the organization.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteInvite = async () => {
        try {
            await InviteMemberService.deleteInvite(orgId, record.id);
            notification.success({
                message: 'Invite Deleted',
                description: 'The invite has been deleted successfully.',
            });
            refresh();
            refreshInvites();
        } catch (e: any) {
            notification.error({
                message: 'Invite Delete Failed',
                description: 'There was an error deleting the invite.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const menu = useMemo(
        () => (
            <Menu>
                {isAdmin && record.status === 'Active' && (
                    <Menu.Item key="edit" onClick={handleEdit}>
                        {' '}
                        <Button
                            type="link"
                            className={styles.OrganizationActionButton}
                            icon={<EditOutlined />}
                        >
                            Edit
                        </Button>
                    </Menu.Item>
                )}
                <Menu.Item key="remove" onClick={handleDelete}>
                    <Button
                        type="link"
                        className={styles.DeleteOrganizationPopoverButton}
                        icon={
                            record.role === 'Admin' ? (
                                <UserDeleteOutlined />
                            ) : (
                                <LogoutOutlined />
                            )
                        }
                    >
                        {isAdmin && record.status !== 'Active'
                            ? 'Delete'
                            : isAdmin
                            ? 'Remove'
                            : 'Leave'}
                    </Button>
                </Menu.Item>
            </Menu>
        ),
        [isAdmin, record.status, record.role]
    );

    return (
        <>
            <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
                disabled={isActiveUser(
                    user?.id,
                    record.id,
                    isAdmin,
                    record.role
                )}
            >
                <Button loading={isLoading} icon={<CcxContextualMenuIcon />} />
            </Dropdown>

            <MemberFormModal
                visible={isEditVisible}
                onClose={handleClose}
                record={record}
                isEditing={true}
                onSubmit={handleUpdateMember}
                testId="EditOrganizationModal"
                isLoading={isLoading}
            />

            <MemberDeletePopover
                record={record}
                isDeleteVisible={isDeleteVisible}
                setIsDeleteVisible={setIsDeleteVisible}
                handleOk={() => handleDeleteMember()}
                isAdmin={isAdmin}
            />
        </>
    );
};

export default MembersActionsList;
