import { Form, Select } from 'antd';
import { useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import DeploymentsItem from '../../types/DeploymentsItem';
import CronInput from '@severalnines/bar-frontend-components/build/lib/DataEntry/CronInput';
import useBackupSettings from '../../core/hooks/useBackupSettings';
import AppLoading from '../AppLoading';
import BackupScheduleInfo from './FormParts/BackupScheduleInfo';
import styles from './BackupsScheduleEditFullForm.module.less';
import { Draft } from '@reduxjs/toolkit';
import { SelectFieldOptions } from '../../core/helpers';

type BackupsScheduleEditFullFormProps = {
    deployment: Draft<DeploymentsItem>;
    testId?: string;
    form: FormInstance;
    onSuccess?: () => void;
    nodesOptions?: SelectFieldOptions[];
};
export default function BackupsScheduleEditFullForm({
    deployment,
    form,
    onSuccess,
    nodesOptions,
    testId = 'BackupsScheduleEditFullForm',
}: BackupsScheduleEditFullFormProps) {
    const { backupSettings, update, loading }: any = useBackupSettings(
        deployment.dataStoreUuid
    );

    const initialValues = useMemo(() => {
        if (loading) return undefined;
        if (!backupSettings) return undefined;
        return {
            cron: backupSettings.fullCron,
            node_id: backupSettings.fullNodeId,
        };
    }, [backupSettings, loading]);

    const handleSubmit = async ({ cron, node_id }: any) => {
        try {
            await update({
                full: {
                    frequency: backupSettings.fullFrequency,
                    cron,
                    node_id,
                },
            });
            onSuccess?.();
        } catch (e: any) {
            console.error(e);
        }
    };

    return loading ? (
        <AppLoading />
    ) : (
        <Form
            form={form}
            data-testid={testId}
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
        >
            <Form.Item
                name="cron"
                label="Start the full backup every"
                className={styles.BackupScheduleModal}
                rules={[
                    {
                        required: true,
                        message: 'Please select an interval',
                    },
                ]}
            >
                <CronInput
                    enableMinutely={false}
                    enableWeekly={false}
                    enableMonthly={false}
                    enableYearly={false}
                />
            </Form.Item>

            {(deployment?.isPostgreSql() ||
                deployment?.isMariaDb() ||
                deployment?.isPercona()) && (
                <Form.Item name="node_id" label="Backup Node">
                    <Select
                        placeholder={'Select node for backups'}
                        className={styles.AppFormSelect}
                    >
                        <Select.Option value={'auto'} key={'auto'}>
                            Auto
                        </Select.Option>
                        {nodesOptions?.map((n: any) => (
                            <Select.Option value={n.value} key={n.value}>
                                {n.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            <BackupScheduleInfo
                form={form}
                message={'Your full backup will start'}
            />
        </Form>
    );
}
